@charset "UTF-8";
/*  ==========================================================================
    Includes
    ==========================================================================

    This is the main includes file. Here we include each of the sections.
    This includes file only concerns it's self with the cascade order of
    the different sections. Each section than controls it's own internal cascade.

    Cascade Index
    -------------
    1.  Vars
        The 'Vars' section holds global variables that all partials should be able to access if needs be. Note partials may have variables within them that are deemed only relevant for that partial. If you add a variable that you want to use across various partials you might want to consider adding it here.

    2.  Functions
        Has useful functions.

    3.  Breakpoints
        The 'Breakpoints' section contains a list of global variables that represent different breakpoint sizes. They are separated from the 'Vars' section because they will make use of some functions, particularly em() and bp-size().

    4.  Mixins
        Has useful mixins.

    5.  Base
        These are styles which effect base elements (p, a, table ect). The 'Base' section includes a reset and parts of normalize.css(https://github.com/necolas/normalize.css) are sprinkled throughout.

    6.  vendor
        If you want to include 3rd party css. They are included here so that the 'Objects' and 'Ui' sections van override styles.

    7.  Objects
        Reusable patterns. May contain generic patterns like the 'Media' object but you may also fill this folder with patterns that are a little more specific to your project.

    8.  Ui
        The main styles for your project.

    9.  Helpers
        Style trumps. Helper classes take precedence over everything else. I recommend only using helper classes if the style the helper class enables is relevant across all viewports otherwise you end up trying to fight the helper classes in the cascade.
*/
/*  ==========================================================================
    Vars
    ========================================================================== */
/*
    colors
    ------
*/
/*
    Fonts
    -----
*/
/*
    Z-indexes
    ---------
    Keep track of z-index by assigning your elements z-index to
    one of these defined levels.
*/
/*
    Widths
    ------
*/
/*
    Spacing units
    -------------
*/
/* 1 */
/*  ==========================================================================
    Functions:Units
    ========================================================================== */
/*
    PX to EM
    --------

    Convert px to em

    $base-font-size is used as a default so when you use the em() function you don't have to provide
    a second parameter if your working with a element with the default font-size
*/
/*
    EM to PX
    --------

    Convert em to px
*/
/*  ==========================================================================
    Functions:Breakpoints
    ========================================================================== */
/* 2 */
/*  ==========================================================================
    Breakpoints
    ========================================================================== */
/* 3 */
/*  ==========================================================================
    Mixins:Respond
    ==========================================================================

    Generate media queries.

    $respond-no-media-queries
    -------------------------
    set this global var in your stylesheet aimed at browsers which do
    not support media queries (ie8) to give them desktop styles only.

    This way they get all the styles but the cascade makes sure they display
    the desktop styles (if your using a mobile first approach to layering the media queries)
*/
/*  ==========================================================================
    Mixins:Rem
    ==========================================================================

    A small mixin for easy use of rem with px as fallback
    usage: @include x-rem(font-size, 14px)
    usage: @include x-rem(marign, 0 12px 2 1.2)
    usage: @include x-rem(padding, 1.5 24px)

    thanks to Eric Meyer for https://github.com/ericam/susy
    and Hans Christian Reinl for http://drublic.de/blog/rem-fallback-sass-less/

    set $base-font-size outside of the mixin in your variables file
*/
/*  ==========================================================================
    Mixins:Visually Hidden
    ========================================================================== */
/* 4 */
/*  ==========================================================================
    Base:Reset
    ========================================================================== */
/*
    1. Use border-box globally
*/
*,
*:after,
*:before {
  box-sizing: border-box;
  /* 1 */ }

/*
    1. reset some stuff
*/
p, blockquote, pre,
dl, dd, form, fieldset, legend,
table, th, td, caption,
hr {
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/*
    1. Correct `block` display not defined for any HTML5 element in IE 8/9. - Normalize:v3.0.1
    1. Correct `block` display not defined for `details` or `summary` in IE 10/11 and Firefox. - Normalize:v3.0.1
    1. Correct `block` display not defined for `main` in IE 11. - Normalize:v3.0.1
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
  /* 1 */ }

/*
    1. Correct `inline-block` display not defined in IE 8/9. - Normalize:v3.0.1
    2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera. - Normalize:v3.0.1
*/
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/*
    1. Prevent modern browsers from displaying `audio` without controls. - Normalize:v3.0.1
    2. Remove excess height in iOS 5 devices. - Normalize:v3.0.1
*/
audio:not([controls]) {
  display: none;
  /* 1 */
  height: 0;
  /* 2 */ }

/*
    1. Address `[hidden]` styling not present in IE 8/9/10. - Normalize:v3.0.1
    2. Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22. - Normalize:v3.0.1
*/
[hidden],
template {
  /* 2 */
  display: none; }

/*  ==========================================================================
    Base:Main
    ========================================================================== */
/*  ==========================================================================
    Base:Headings
    ========================================================================== */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "brother-1816", sans-serif; }

h1 {
  font-size: 30px; }

h2 {
  font-size: 25px; }

h3 {
  font-size: 22px; }

h4 {
  font-size: 20px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

/*  ==========================================================================
    Base:Links
    ========================================================================== */
/*
    1. Remove the gray background color from active links in IE 10. - Normalize:v3.0.1
*/
a {
  color: blue;
  background: transparent;
  /* 1 */ }
  a:hover, a:focus {
    color: #6666ff; }

/*  ==========================================================================
    Base:Lists
    ========================================================================== */
/*
    1. Remove vertical spacing from nested lists.
*/
li > ul,
li > ol {
  margin-bottom: 0;
  /* 1 */ }

/*
    1. Have a numbered `ul` without the semantics implied by using an `ol`.
*/
/*ul*/
.numbered-list {
  list-style-type: decimal;
  /* 1 */ }

/*  ==========================================================================
    Base:Images
    ========================================================================== */
/*
    1. So that `alt` text is visually offset if images don’t load.
    2. Fluid images.
    3. Remove border when inside `a` element in IE 8/9. - Normalize:v3.0.1
*/
img {
  font-style: italic;
  /* 1 */
  max-width: 100%;
  /* 2 */
  border: 0;
  /* 3 */ }

/*
    1. Images in `figure` elements.
*/
figure > img {
  display: block;
  /* 1 */ }

/*  ==========================================================================
    Base:Type
    ========================================================================== */
/*
    1. Remove underlines from potentially troublesome elements.
*/
u,
ins {
  text-decoration: none;
  /* 1 */ }

/*
    1. Apply faux underline via `border-bottom`.
*/
ins {
  border-bottom: 1px solid;
  /* 1 */ }

/*
    1. Give a help cursor to elements that give extra info on `:hover`.
*/
abbr[title],
dfn[title] {
  cursor: help;
  /* 1 */ }

/*
    1. Address styling not present in IE 8/9, Safari 5, and Chrome. - Normalize:v3.0.1
*/
abbr[title] {
  border-bottom: 1px dotted;
  /* 1 */ }

/*
    1. Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome. - Normalize:v3.0.1
*/
b,
strong {
  font-weight: bold;
  /* 1 */ }

/*
    1. Address styling not present in Safari 5 and Chrome. - Normalize:v3.0.1
*/
dfn {
  font-style: italic;
  /* 1 */ }

/*
    Address styling not present in IE 8/9. - Normalize:v3.0.1
*/
mark {
  background: #ff0;
  /* 1 */
  color: #000;
  /* 1 */ }

/*
    1. Set consistent quote types. - Normalize:v2.1.0
*/
q {
  quotes: "\201C" "\201D" "\2018" "\2019";
  /* 1 */ }

/*
    1. Address inconsistent and variable font size in all browsers. - Normalize:v3.0.1
*/
small {
  font-size: 0.55556em;
  /* 1 */ }

/*
    1:sub-sup. Prevent `sub` and `sup` affecting `line-height` in all browsers. - Normalize:v3.0.1
*/
sub,
sup {
  font-size: 75%;
  /* 1:sub-sup */
  line-height: 0;
  /* 1:sub-sup */
  position: relative;
  /* 1:sub-sup */
  vertical-align: baseline;
  /* 1:sub-sup */ }

sup {
  top: -0.5em;
  /* 1:sub-sup */ }

sub {
  bottom: -0.25em;
  /* 1:sub-sup */ }

/*  ==========================================================================
    Base:Forms
    ========================================================================== */
/*
    1. Give form elements some cursor interactions...
*/
label,
input,
textarea,
button,
select,
option {
  cursor: pointer;
  /* 1 */ }

.text-input:active,
.text-input:focus,
textarea:active,
textarea:focus {
  cursor: text;
  /* 1 */ }

/*
    Known limitation: by default, Chrome and Safari on OS X allow very limited
    styling of `select`, unless a `border` property is set.

    1. Correct color not being inherited.
       Known issue: affects color of disabled elements. - Normalize:v3.0.1
    2. Correct font properties not being inherited. - Normalize:v3.0.1
    3. Address margins set differently in Firefox 4+, Safari, and Chrome. - Normalize:v3.0.1
*/
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/*
    1. Address `overflow` set to `hidden` in IE 8/9/10/11. - Normalize:v3.0.1
*/
button {
  overflow: visible;
  /* 1 */ }

/*
    1. Address inconsistent `text-transform` inheritance for `button` and `select`.
       All other form control elements do not inherit `text-transform` values.
       Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
       Correct `select` style inheritance in Firefox. - Normalize:v3.0.1
*/
button,
select {
  text-transform: none;
  /* 1 */ }

/*
    1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
       and `video` controls. - Normalize:v3.0.1
    2. Correct inability to style clickable `input` types in iOS. - Normalize:v3.0.1
    3. Improve usability and consistency of cursor style between image-type
       `input` and others. - Normalize:v3.0.1
*/
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/*
    1. Re-set default cursor for disabled elements. - Normalize:v3.0.1
*/
button[disabled],
html input[disabled] {
  cursor: default; }

/*
    1. Remove inner padding and border in Firefox 4+. - Normalize:v3.0.1
*/
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/*
    1. Address Firefox 4+ setting `line-height` on `input` using `!important` in
       the UA stylesheet. - Normalize:v3.0.1
*/
input {
  line-height: normal;
  /* 1 */ }

/*
    It's recommended that you don't attempt to style these elements.
    Firefox's implementation doesn't respect box-sizing, padding, or width.

    1. Address box sizing set to `content-box` in IE 8/9/10. - Normalize:v3.0.1
    2. Remove excess padding in IE 8/9/10. - Normalize:v3.0.1
*/
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/*
    1. Fix the cursor style for Chrome's increment/decrement buttons. For certain
       `font-size` values of the `input`, it causes the cursor style of the
       decrement button to change from `default` to `text`. - Normalize:v3.0.1
*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
  /* 1 */ }

/*
    1. Address `appearance` set to `searchfield` in Safari and Chrome.
*/
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */ }

/*
    1. Remove inner padding and search cancel button in Safari and Chrome on OS X.
       Safari (but not Chrome) clips the cancel button when the search input has
       padding (and `textfield` appearance). - Normalize:v3.0.1
*/
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  /* 1 */ }

/*
    1. Correct `color` not being inherited in IE 8/9/10/11. - Normalize:v3.0.1
    2. Remove padding so people aren't caught out if they zero out fieldsets. - Normalize:v3.0.1
*/
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/*
    1. Remove default vertical scrollbar in IE 8/9/10/11. - Normalize:v3.0.1
*/
textarea {
  overflow: auto;
  /* 1 */ }

/*
    1. Don't inherit the `font-weight` (applied by a rule above).
       NOTE: the default cannot safely be changed in Chrome and Safari on OS X. - Normalize:v3.0.1
*/
optgroup {
  font-weight: bold;
  /* 1 */ }

.form-errors li {
  color: #b94a48; }

/*  ==========================================================================
    Base:Quotes
    ========================================================================== */
/*  ==========================================================================
    Base:Grouping
    ========================================================================== */
/*
    1. Address margin not present in IE 8/9 and Safari 5. - Normalize:v3.0.1
*/
figure {
  margin: 1em 40px;
  /* 1 */ }

/*
    1. Address differences between Firefox and other browsers. - Normalize:v3.0.1
*/
hr {
  -moz-box-sizing: content-box;
  /* 1 */
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */ }

/*
    1. Contain overflow in all browsers. - Normalize:v3.0.1
*/
pre {
  overflow: auto;
  /* 1 */ }

/*
    1. Address odd `em`-unit font size rendering in all browsers. - Normalize:v2.1.0
*/
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 1 */ }

/*  ==========================================================================
    Base:Tables
    ========================================================================== */
/*
    1. Remove most spacing between table cells. - Normalize:v3.0.1
*/
table {
  border-collapse: collapse;
  /* 1 */
  border-spacing: 0;
  /* 1 */ }

/*  ==========================================================================
    Base:SVG
    ========================================================================== */
/*
    1. Correct overflow not hidden in IE 9/10/11. - Normalize:v3.0.1
*/
svg:not(:root) {
  overflow: hidden;
  /* 1 */ }

/*  ==========================================================================
    Base:Media
    ========================================================================== */
/*
    1. http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
*/
@-ms-viewport {
  width: device-width;
  /* 1 */ }

/* 5 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

/* 6 */
.alert {
  padding: 8px 14px;
  margin: 0 0 1em;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  clear: both; }

.alert--success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847; }

.alert--error {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48; }

/*  ==========================================================================
    Objects:Button
    ==========================================================================

    The button classes are best applied to links, buttons, and submit inputs.
    These components can be used in forms, as calls to action, or as part of the
    general UI of the site/app.

    Recommended use:

    <a class="Button [Button--modifier]" role="button" href="[url]">Button text</a>
    <button class="Button [Button--modifier]" type="submit">Button text</button>
    <input class="Button [Button--modifier]" type="submit" value="Button text">

    Button template

    Build on this component using rulesets in your application-level CSS.

    1.  Corrects inability to style clickable `input` types in iOS
    2.  Normalize `box-sizing` across all elements that this component could be
        applied to.
    3.  Allow easier styling.
    4.  Normalize `line-height`. For `input`, it can't be changed from `normal` in Firefox 4+.
    5.  Normalise box model styles.
    6.  Prevent button text from being selectable.
    7.  Stop buttons wrapping and looking broken.
*/
.button {
  width: 200px;
  height: 50px;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600; }
  .button.small-fit {
    width: 100%;
    height: 40px;
    font-size: 14px; }
  .button.large {
    width: 325px;
    height: 50px; }
  .button.large-text {
    height: auto;
    padding: 13px 10px !important;
    width: auto;
    white-space: normal; }
  .button.trustpilot {
    background-color: #323e48;
    text-align: center;
    color: #FFF;
    font-size: 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
    flex-wrap: wrap;
    max-width: 250px;
    height: auto; }
    @media (min-width: 34.375em) {
      .button.trustpilot {
        width: 325px;
        max-width: 325px;
        flex-wrap: nowrap;
        height: 70px;
        padding: 10px; } }
    .button.trustpilot img {
      max-width: 100px; }
    .button.trustpilot span {
      padding-top: 3px; }
  .button.orange {
    background-color: #ea5b0c;
    color: #FFF !important; }

.btn {
  -webkit-appearance: none;
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  cursor: pointer;
  display: inline-block;
  /* 3 */
  line-height: normal;
  /* 4 */
  margin: 0;
  border: 0;
  /* 5 */
  padding-top: 13px;
  /* 5 */
  padding-bottom: 0;
  /* 5 */
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* 6 */
  white-space: nowrap;
  /* 7 */
  position: relative;
  text-decoration: none;
  /*
        Remove excess padding and border in Firefox 4+
    */ }
  .btn.small-fit {
    padding-top: 9px; }
  .btn, .btn:hover {
    text-decoration: none; }
  .btn:disabled, .btn.is-disabled {
    cursor: default; }
  .btn::-moz-focus-inner {
    border: 0;
    padding: 0; }

/*  ==========================================================================
    Objects:Grids
    ==========================================================================

    These grids are mostly from css wizardry grids:- https://github.com/csswizardry/csswizardry-grids
    but there is also some stuff from an older version of SUIT grid component:- https://github.com/suitcss/components-grid

    Fluid and nestable grid system, e.g.:

    <div class="grid">

        <div class="grid__cell one-third">
            <p>One third grid</p>
        </div><!--

     --><div class="grid__cell two-thirds">
            <p>Two thirds grid</p>
        </div><!--

     --><div class="grid__cell one-half">
            <p>One half grid</p>
        </div><!--

     --><div class="grid__cell one-quarter">
            <p>One quarter grid</p>
        </div><!--

     --><div class="grid__cell one-quarter">
            <p>One quarter grid</p>
        </div>

    </div>

*/
/*
    All content must be contained within child Grid-cell elements.

    1.  Account for browser defaults of elements that might be the root node of
        the component.
*/
.grid {
  display: block;
  /* 1 */
  padding: 0;
  /* 1 */
  margin: 0;
  /* 1 */
  margin-left: -1.66667em; }

/*
    Very infrequently occurring grid wrappers as children of grid wrappers.
*/
.grid > .grid {
  margin-left: 0; }

/*
    No explicit width by default. Rely on combining `Grid-cell` with a dimension
    utility or a component class that extends 'grid'.

    1. Fundamentals of the non-float grid layout.
    2. Controls vertical positioning of units.
    3. Make cells full-width by default.
*/
.grid__cell {
  display: inline-block;
  /* 1 */
  vertical-align: top;
  /* 2 */
  width: 100%;
  /* 3 */
  padding-left: 1.66667em; }

/*
    Gutterless grids have all the properties of regular grids, minus any spacing.
*/
.grid--full {
  margin-left: 0; }
  .grid--full > .grid__cell {
    padding-left: 0; }

/*
    Reversed grids allow you to structure your source in the opposite order to
    how your rendered layout will appear. Extends `.grid`.
*/
.grid--rev {
  direction: rtl;
  text-align: left; }
  .grid--rev > .grid__cell {
    direction: ltr;
    text-align: left; }

/*  ==========================================================================
    Objects:Media
    ==========================================================================

    Image and text blocks, as devised by @stubbornella
    stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code

    This object is useful to document the usage of the overflow to create a new block formatting context pattern. I don't find myself using this actual object much as in a responsive context i often want a component to transform into a media object, so if i use the this object i end up fighting the styles.

    Dependencies
    ------------
    * group (clearfix) - must be applied to the main element.

    HTML
    ----
    <div class=media group>
        <img src=http://placehold.it/150x150 alt="" class=media__img>
        <p class=media__body>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>

*/
.media {
  display: block; }

.media__img {
  float: left;
  margin-right: 1.66667em; }

/*
    Reversed image location (right instead of left).
*/
.media__img--rev {
  float: right;
  margin-left: 1.66667em; }

.media__img img,
.media__img--rev img {
  display: block; }

/*
    1. Create a new block formatting context. See - http://www.stubbornella.org/content/2009/07/23/overflow-a-secret-benefit/
*/
.media__body {
  overflow: hidden;
  /* 1 */ }

.media__body,
.media__body > :last-child {
  margin-bottom: 0; }

/*  ==========================================================================
    Objects:Nav
    ==========================================================================

    By @csswizardry and taken from a version of inuit.css https://github.com/inuitcss

    When used on an `ol` or `ul`, this class throws the list into horizontal mode
    e.g.:

    Dependencies
    ------------
    * group (clearfix) - must be applied to the main element.

    HTML
    ----
    <ul class=nav group>
        <li><a href=#>Home</a></li>
        <li><a href=#>About</a></li>
        <li><a href=#>Portfolio</a></li>
        <li><a href=#>Contact</a></li>
    </ul>
*/
/*
    1:  Set line-height so positing does not depend on the global line-height set on the html element.
    Overwrite this value on a particular nav if you need to.
    This way if the global line-height changes it does not effect the navigations position.
*/
.nav {
  list-style: none;
  margin-left: 0;
  line-height: 1em;
  /* 1 */ }
  .nav > li,
  .nav > li > a {
    display: inline-block; }

/*
    extends .nav and throws the list into vertical mode.
*/
.nav--stacked > li {
  display: list-item; }
  .nav--stacked > li > a {
    display: block; }

/*
    Force a nav to occupy 100% of the available width of its parent. Extends
    .nav, original idea @pimpl
*/
.nav--fit {
  display: table;
  width: 100%; }
  .nav--fit > li {
    display: table-cell; }
    .nav--fit > li > a {
      display: block; }

/* 7 */
.wm-links a {
  background: none;
  display: inline-block;
  color: #333;
  border: 1px solid #333;
  margin: 16px;
  font-size: 16px;
  text-align: left;
  white-space: normal;
  vertical-align: top;
  position: relative;
  padding: 10px 24px 10px 14px; }
  .wm-links a:after {
    content: '>';
    display: block;
    position: absolute;
    bottom: 10px;
    right: 5px; }
  .wm-links a:hover {
    transition: all .2s ease-in;
    background: #333;
    color: #d3d3d3; }

.wm-buttons a {
  background: none;
  display: inline-block;
  color: #333;
  border: 1px solid #333;
  margin: 16px;
  font-size: 16px;
  text-align: left;
  white-space: normal;
  vertical-align: top;
  position: relative;
  padding: 10px 24px 10px 14px; }
  .wm-buttons a:after {
    content: '>';
    display: block;
    position: absolute;
    bottom: 10px;
    right: 5px; }
  .wm-buttons a:hover {
    transition: all .2s ease-in;
    background: #333;
    color: #d3d3d3; }

header.page-head {
  width: 100%;
  padding: 20px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center; }
  @media (min-width: 34.375em) {
    header.page-head {
      padding: 20px 50px 0; } }
  @media (min-width: 64.0625em) {
    header.page-head {
      padding: 20px 0 30px; } }
  header.page-head .contact-info {
    text-decoration: none;
    color: #323e48;
    font-size: 14px;
    font-weight: 600; }
    header.page-head .contact-info a {
      color: #ea5b0c; }
  header.page-head .logo-wrap {
    max-width: 205px;
    width: 100%;
    flex-basis: 205px; }
    @media (min-width: 34.375em) {
      header.page-head .logo-wrap {
        max-width: 270px;
        width: 100%;
        flex-basis: 270px; } }
    header.page-head .logo-wrap img {
      width: 100%; }
  header.page-head .nav-wrap {
    display: flex;
    justify-content: space-between; }
    @media (min-width: 64.0625em) {
      header.page-head .nav-wrap {
        width: 100%; } }
    header.page-head .nav-wrap nav.header-nav {
      display: none; }
      @media (min-width: 64.0625em) {
        header.page-head .nav-wrap nav.header-nav {
          display: block;
          width: 100%;
          margin-top: 15px; } }
      header.page-head .nav-wrap nav.header-nav ul {
        padding: 0 0 0 0;
        margin: 0;
        display: flex;
        justify-content: space-between; }
        header.page-head .nav-wrap nav.header-nav ul li {
          list-style-type: none;
          margin-right: 15px;
          position: relative; }
          header.page-head .nav-wrap nav.header-nav ul li:last-of-type {
            margin-right: 0px; }
          header.page-head .nav-wrap nav.header-nav ul li a {
            text-decoration: none;
            text-transform: uppercase;
            color: #323e48;
            font-size: 12px;
            font-weight: 600; }
            header.page-head .nav-wrap nav.header-nav ul li a:hover {
              text-decoration: underline; }
          header.page-head .nav-wrap nav.header-nav ul li .dropdown-wrap {
            display: none; }
          header.page-head .nav-wrap nav.header-nav ul li:hover .dropdown-wrap {
            display: block;
            position: absolute;
            top: 15px;
            width: 250px;
            z-index: 999;
            padding-top: 20px; }
            header.page-head .nav-wrap nav.header-nav ul li:hover .dropdown-wrap ul {
              padding: 10px;
              margin: 0;
              background-color: #FFF;
              border-top: 2px solid #ea5b0c;
              width: 100%;
              display: block; }
              header.page-head .nav-wrap nav.header-nav ul li:hover .dropdown-wrap ul::before {
                content: "\25b2";
                color: #ea5b0c;
                position: absolute;
                top: 3px;
                left: 10px;
                z-index: 900; }
              header.page-head .nav-wrap nav.header-nav ul li:hover .dropdown-wrap ul li {
                border-bottom: 1px solid #323e48;
                margin-right: 0px;
                padding-bottom: 9px;
                margin-bottom: 8px; }
                header.page-head .nav-wrap nav.header-nav ul li:hover .dropdown-wrap ul li a {
                  display: block;
                  line-height: 18px; }
                header.page-head .nav-wrap nav.header-nav ul li:hover .dropdown-wrap ul li:last-of-type {
                  border-bottom: none;
                  padding-bottom: 0px;
                  margin-bottom: 0px; }
                header.page-head .nav-wrap nav.header-nav ul li:hover .dropdown-wrap ul li .third-level {
                  display: none; }
                header.page-head .nav-wrap nav.header-nav ul li:hover .dropdown-wrap ul li:hover .third-level {
                  display: block;
                  left: 210px;
                  top: -29px;
                  padding-left: 20px; }
                  header.page-head .nav-wrap nav.header-nav ul li:hover .dropdown-wrap ul li:hover .third-level ul {
                    border-top: none;
                    border-left: 2px solid #ea5b0c; }
                    header.page-head .nav-wrap nav.header-nav ul li:hover .dropdown-wrap ul li:hover .third-level ul::before {
                      content: "\25c0";
                      color: #ea5b0c;
                      position: absolute;
                      top: 27px;
                      left: 8px;
                      z-index: 900; }
    header.page-head .nav-wrap nav.main-nav {
      padding-top: 12px;
      display: block; }
      @media (min-width: 34.375em) {
        header.page-head .nav-wrap nav.main-nav {
          padding-top: 15px; } }
      @media (min-width: 64.0625em) {
        header.page-head .nav-wrap nav.main-nav {
          display: none; } }
      header.page-head .nav-wrap nav.main-nav .menu-btn {
        width: 33px;
        height: 25px; }
        header.page-head .nav-wrap nav.main-nav .menu-btn span {
          display: block;
          width: 100%;
          height: 5px;
          background-color: #ea5b0c;
          margin-bottom: 5px;
          cursor: pointer; }
          header.page-head .nav-wrap nav.main-nav .menu-btn span:last-of-type {
            margin-bottom: 0px; }
      header.page-head .nav-wrap nav.main-nav .mobile.active {
        display: block;
        position: fixed;
        align-items: center;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        z-index: 9999;
        background-color: #ea5b0c; }
        header.page-head .nav-wrap nav.main-nav .mobile.active li {
          width: 100%;
          padding: 10px 0;
          text-align: left;
          background-color: #FFF;
          border-bottom: 2px solid #ea5b0c; }
          header.page-head .nav-wrap nav.main-nav .mobile.active li a {
            text-decoration: none;
            color: #323e48;
            font-weight: 600;
            font-size: 14px;
            position: relative;
            width: 100%;
            padding-left: 10px; }
            header.page-head .nav-wrap nav.main-nav .mobile.active li a.mob-drop::after {
              content: "\25bc";
              color: #ea5b0c;
              position: absolute;
              top: 1px;
              right: 15px;
              z-index: 900; }
            header.page-head .nav-wrap nav.main-nav .mobile.active li a.mob-drop.active::after {
              content: "\25b2";
              color: #ea5b0c;
              position: absolute;
              top: 1px;
              right: 15px;
              z-index: 900; }
          header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown {
            display: none; }
            header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown.active {
              display: block; }
              header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown.active ul {
                display: block;
                padding: 0;
                margin: 0; }
                header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown.active ul li {
                  list-style-type: none; }
                  header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown.active ul li:last-of-type {
                    border-bottom: 0px; }
                  header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown.active ul li.third-drop {
                    position: relative; }
                    header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown.active ul li.third-drop::after {
                      content: "\25bc";
                      color: #ea5b0c;
                      position: absolute;
                      top: 11px;
                      right: 15px;
                      z-index: 900; }
                    header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown.active ul li.third-drop.active::after {
                      content: "\25b2";
                      color: #ea5b0c;
                      position: absolute;
                      top: 11px;
                      right: 15px;
                      z-index: 900; }
                  header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown.active ul li a {
                    font-size: 12px;
                    padding-left: 20px;
                    display: block;
                    width: 85%; }
                  header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown.active ul li .third-level {
                    display: none; }
                    header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown.active ul li .third-level.active {
                      display: block; }
                    header.page-head .nav-wrap nav.main-nav .mobile.active li .mob-dropdown.active ul li .third-level ul li a {
                      padding-left: 30px;
                      width: 100%;
                      display: block; }
          header.page-head .nav-wrap nav.main-nav .mobile.active li.close-mobile {
            display: flex;
            background-color: #ea5b0c;
            justify-content: center;
            align-items: center; }
            header.page-head .nav-wrap nav.main-nav .mobile.active li.close-mobile span {
              color: #FFF;
              font-size: 12px;
              font-weight: 600;
              display: block;
              margin-right: 5px; }
            header.page-head .nav-wrap nav.main-nav .mobile.active li.close-mobile img {
              max-width: 15px; }
      header.page-head .nav-wrap nav.main-nav ul {
        display: none; }

html, body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  width: 100%;
  max-width: 100%;
  font-family: "brother-1816", sans-serif;
  font-size: 16px;
  background-color: #f5f6f6; }

.inner-wrapper {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  position: relative; }

footer {
  background-color: #323e48;
  padding: 50px; }
  footer .top {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    @media (min-width: 34.375em) {
      footer .top {
        flex-wrap: nowrap;
        justify-content: space-between; } }
    footer .top ul {
      padding: 0;
      margin: 15px 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media (min-width: 34.375em) {
        footer .top ul {
          margin: 0; } }
      footer .top ul li {
        list-style-type: none;
        margin: 0 5px; }
    footer .top .trustpilot {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      max-width: 100%;
      width: 100%;
      flex-wrap: wrap; }
      @media (min-width: 34.375em) {
        footer .top .trustpilot {
          max-width: 290px;
          width: 100%;
          flex-wrap: nowrap;
          margin: 0; } }
      @media (min-width: 64.0625em) {
        footer .top .trustpilot {
          text-align: left;
          margin: 0;
          width: auto;
          max-width: none; } }
      footer .top .trustpilot a {
        text-decoration: none;
        width: 100%; }
      footer .top .trustpilot span {
        display: none; }
        @media (min-width: 34.375em) {
          footer .top .trustpilot span {
            display: block;
            width: auto;
            flex-basis: auto;
            margin-right: 8px;
            padding-top: 2px;
            color: #FFF;
            font-size: 14px;
            text-transform: uppercase; } }
      footer .top .trustpilot img {
        margin: 5px auto 0;
        max-width: 100px; }
        @media (min-width: 34.375em) {
          footer .top .trustpilot img {
            margin: 0; } }
  footer .bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (min-width: 64.0625em) {
      footer .bottom {
        flex-wrap: nowrap; } }
    footer .bottom .col-50 {
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
      text-align: center; }
      @media (min-width: 64.0625em) {
        footer .bottom .col-50 {
          flex-basis: 48%;
          max-width: 48%;
          text-align: left; } }
      footer .bottom .col-50 h3 {
        color: #FFF;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 10px; }
      footer .bottom .col-50 p {
        font-family: "myriad-pro", sans-serif;
        font-size: 12px;
        color: #91989e;
        line-height: 16px;
        margin-bottom: 8px; }
        footer .bottom .col-50 p:last-of-type {
          margin-bottom: 0px; }
      footer .bottom .col-50 nav ul {
        padding: 40px 0 0 0;
        margin: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap; }
        @media (min-width: 64.0625em) {
          footer .bottom .col-50 nav ul {
            justify-content: flex-start; } }
        footer .bottom .col-50 nav ul li {
          list-style-type: none;
          margin-bottom: 5px;
          flex-basis: 100%; }
          @media (min-width: 34.375em) {
            footer .bottom .col-50 nav ul li {
              flex-basis: auto;
              margin-right: 10px; } }
          footer .bottom .col-50 nav ul li a {
            color: #FFF;
            font-size: 14px;
            text-decoration: none; }
            footer .bottom .col-50 nav ul li a:hover {
              text-decoration: underline; }
  footer .copyright {
    font-family: "myriad-pro", sans-serif;
    font-size: 14px;
    color: #91989e;
    width: 100%;
    margin-top: 15px;
    text-align: center; }
    @media (min-width: 64.0625em) {
      footer .copyright {
        text-align: left; } }
  footer .credit {
    color: #91989e;
    font-size: 14px;
    width: 100%;
    font-family: "myriad-pro", sans-serif;
    margin-top: 5px;
    text-align: center; }
    @media (min-width: 64.0625em) {
      footer .credit {
        text-align: left; } }
    footer .credit a {
      color: #91989e;
      text-decoration: none; }
      footer .credit a:hover {
        text-decoration: underline; }

.banner-block {
  height: 280px;
  position: relative;
  width: 100%;
  max-width: 100%; }
  @media (min-width: 34.375em) {
    .banner-block {
      height: 390px; } }
  .banner-block.contact {
    height: 200px;
    background: url("../images/Homepage_Banner_Buildings-01.svg") no-repeat bottom left;
    background-size: 100%; }
    @media (min-width: 34.375em) {
      .banner-block.contact {
        height: 340px; } }
    .banner-block.contact .float-element {
      display: none; }
  .banner-block.calculator {
    height: 245px; }
    .banner-block.calculator .text-wrap {
      text-align: center;
      padding: 40px 25px 0;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (min-width: 34.375em) {
        .banner-block.calculator .text-wrap {
          text-align: left;
          padding: 0px 40px; } }
      .banner-block.calculator .text-wrap h1 {
        line-height: 36px;
        max-width: 615px;
        width: 100%; }
        @media (min-width: 34.375em) {
          .banner-block.calculator .text-wrap h1 {
            line-height: 56px; } }
      .banner-block.calculator .text-wrap img {
        display: none; }
        @media (min-width: 34.375em) {
          .banner-block.calculator .text-wrap img {
            display: block; } }
  .banner-block.home {
    height: 400px; }
    @media (min-width: 34.375em) {
      .banner-block.home {
        height: 510px; } }
    .banner-block.home .text-wrap {
      padding: 0 20px; }
      @media (min-width: 34.375em) {
        .banner-block.home .text-wrap {
          padding: 0 215px; } }
      @media (min-width: 64.0625em) {
        .banner-block.home .text-wrap {
          padding: 0 115px; } }
      .banner-block.home .text-wrap h1 {
        font-size: 36px;
        line-height: 46px; }
        @media (min-width: 34.375em) {
          .banner-block.home .text-wrap h1 {
            font-size: 50px;
            line-height: auto; } }
      .banner-block.home .text-wrap p {
        font-size: 24px; }
        @media (min-width: 34.375em) {
          .banner-block.home .text-wrap p {
            font-size: 36px; } }
    .banner-block.home .balloon {
      height: 100px;
      width: 55px;
      background: url("../images/Homepage_Banner_Sky_Elements-02.svg") no-repeat center center;
      bottom: 165px;
      left: 228px;
      display: none;
      animation-duration: 6s;
      animation-name: balloon-anim;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: linear; }
      @media (min-width: 64.0625em) {
        .banner-block.home .balloon {
          display: block; } }

@keyframes balloon-anim {
  0% {
    left: 228px;
    bottom: 165px; }
  25% {
    left: 238px;
    bottom: 175px; }
  50% {
    left: 248px;
    bottom: 185px; }
  75% {
    right: 258px;
    bottom: 195px; }
  100% {
    right: 268px;
    bottom: 205px; } }
    .banner-block.home .cloud-one {
      height: 55px;
      width: 135px;
      background: url("../images/Homepage_Banner_Sky_Elements-01.svg") no-repeat center center;
      bottom: 125px;
      left: 63px;
      display: none;
      animation-duration: 15s;
      animation-name: cloud-one-anim;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: linear; }
      @media (min-width: 64.0625em) {
        .banner-block.home .cloud-one {
          display: block; } }

@keyframes cloud-one-anim {
  0% {
    left: 63px; }
  5% {
    left: 60px; }
  10% {
    left: 57px; }
  15% {
    left: 54px; }
  20% {
    left: 51px; }
  25% {
    left: 48px; }
  30% {
    left: 45px; }
  35% {
    left: 42px; }
  40% {
    left: 39px; }
  45% {
    left: 36px; }
  50% {
    left: 33px; }
  55% {
    left: 30px; }
  60% {
    left: 28px; }
  65% {
    left: 25px; }
  70% {
    left: 22px; }
  75% {
    left: 19px; }
  80% {
    left: 16px; }
  85% {
    left: 14px; }
  90% {
    left: 11px; }
  95% {
    left: 9px; }
  100% {
    left: 6px; } }
    .banner-block.home .cloud-two {
      height: 45px;
      width: 110px;
      background: url("../images/Homepage_Banner_Sky_Elements-03.svg") no-repeat center center;
      bottom: 140px;
      left: 340px;
      display: none;
      animation-duration: 15s;
      animation-name: cloud-two-anim;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: linear; }
      @media (min-width: 64.0625em) {
        .banner-block.home .cloud-two {
          display: block; } }

@keyframes cloud-two-anim {
  0% {
    left: 340px; }
  5% {
    left: 337px; }
  10% {
    left: 334px; }
  15% {
    left: 331px; }
  20% {
    left: 328px; }
  25% {
    left: 325px; }
  30% {
    left: 322px; }
  35% {
    left: 319px; }
  40% {
    left: 316px; }
  45% {
    left: 313px; }
  50% {
    left: 310px; }
  55% {
    left: 307px; }
  60% {
    left: 304px; }
  65% {
    left: 301px; }
  70% {
    left: 298px; }
  75% {
    left: 295px; }
  80% {
    left: 292px; }
  85% {
    left: 289px; }
  90% {
    left: 286px; }
  95% {
    left: 283px; }
  100% {
    left: 280px; } }
    .banner-block.home .cloud-three {
      height: 57px;
      width: 135px;
      background: url("../images/Homepage_Banner_Sky_Elements-05.svg") no-repeat center center;
      bottom: 125px;
      right: 65px;
      display: none;
      animation-duration: 15s;
      animation-name: cloud-three-anim;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: linear; }
      @media (min-width: 64.0625em) {
        .banner-block.home .cloud-three {
          display: block; } }

@keyframes cloud-three-anim {
  0% {
    right: 65px; }
  5% {
    right: 68px; }
  10% {
    right: 71px; }
  15% {
    right: 74px; }
  20% {
    right: 77px; }
  25% {
    right: 80px; }
  30% {
    right: 83px; }
  35% {
    right: 86px; }
  40% {
    right: 89px; }
  45% {
    right: 90px; }
  50% {
    right: 93px; }
  55% {
    right: 96px; }
  60% {
    right: 99px; }
  65% {
    right: 102px; }
  70% {
    right: 105px; }
  75% {
    right: 108px; }
  80% {
    right: 111px; }
  85% {
    right: 114px; }
  90% {
    right: 117px; }
  95% {
    right: 120px; }
  100% {
    right: 123px; } }
    .banner-block.home .gulls {
      width: 50px;
      height: 35px;
      background: url("../images/Homepage_Banner_Sky_Elements-04.svg") no-repeat center center;
      bottom: 130px;
      right: 455px;
      display: none;
      animation-duration: 6s;
      animation-name: gulls-anim;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: linear; }
      @media (min-width: 64.0625em) {
        .banner-block.home .gulls {
          display: block; } }

@keyframes gulls-anim {
  0% {
    right: 455px;
    bottom: 130px; }
  25% {
    right: 445px;
    bottom: 134px; }
  50% {
    right: 435px;
    bottom: 138px; }
  75% {
    right: 425px;
    bottom: 134px; }
  100% {
    right: 415px;
    bottom: 130px; } }
  .banner-block .text-wrap {
    padding: 0 20px 0;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
    z-index: 500; }
    @media (min-width: 34.375em) {
      .banner-block .text-wrap {
        padding: 0 70px 0; } }
    @media (min-width: 64.0625em) {
      .banner-block .text-wrap {
        padding: 0 115px 0; } }
    .banner-block .text-wrap h1 {
      font-weight: 600;
      font-size: 26px;
      color: #323e48;
      text-transform: uppercase;
      margin-bottom: 10px; }
      @media (min-width: 34.375em) {
        .banner-block .text-wrap h1 {
          font-size: 36px; } }
      @media (min-width: 64.0625em) {
        .banner-block .text-wrap h1 {
          font-size: 42px; } }
    .banner-block .text-wrap p {
      color: #ea5b0c;
      font-weight: 400;
      font-size: 16px;
      margin: 0 0 40px; }
      @media (min-width: 34.375em) {
        .banner-block .text-wrap p {
          font-size: 26px; } }
  .banner-block.accordion-banner-bg {
    background: url("../images/Homepage_Banner_Buildings_FARM_UPDATE-01.svg") no-repeat bottom left;
    background-size: 100%;
    height: 280px;
    min-height: 280px; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .banner-block.accordion-banner-bg {
        background: url("../images/Homepage_Banner_Buildings_FARM_UPDATE-01.svg") no-repeat 0px 132px; } }
    @media (min-width: 34.375em) {
      .banner-block.accordion-banner-bg {
        height: 380px;
        min-height: 380px; } }
    @media (min-width: 64.0625em) {
      .banner-block.accordion-banner-bg {
        height: 450px;
        min-height: 450px; } }
  .banner-block .float-element {
    position: absolute; }
  .banner-block .cityscape {
    background: url("../images/Homepage_Banner_Buildings_FARM_UPDATE-01.svg") no-repeat bottom left;
    background-size: 100%;
    width: 100%;
    max-width: 100%;
    height: 70px;
    bottom: 0;
    left: 0;
    z-index: 850; }
    @media (min-width: 34.375em) {
      .banner-block .cityscape {
        height: 175px; } }
  .banner-block .balloon {
    height: 100px;
    width: 55px;
    background: url("../images/Homepage_Banner_Sky_Elements-02.svg") no-repeat center center;
    bottom: 65px;
    right: 228px;
    display: none; }
    @media (min-width: 64.0625em) {
      .banner-block .balloon {
        display: block; } }
  .banner-block .cloud-one {
    height: 55px;
    width: 135px;
    background: url("../images/Homepage_Banner_Sky_Elements-01.svg") no-repeat center center;
    bottom: 125px;
    left: 63px;
    display: none; }
    @media (min-width: 64.0625em) {
      .banner-block .cloud-one {
        display: block; } }
  .banner-block .cloud-two {
    height: 45px;
    width: 110px;
    background: url("../images/Homepage_Banner_Sky_Elements-03.svg") no-repeat center center;
    bottom: 140px;
    left: 340px;
    display: none; }
    @media (min-width: 64.0625em) {
      .banner-block .cloud-two {
        display: block; } }
  .banner-block .cloud-three {
    height: 57px;
    width: 135px;
    background: url("../images/Homepage_Banner_Sky_Elements-05.svg") no-repeat center center;
    bottom: 125px;
    right: 65px;
    display: none; }
    @media (min-width: 64.0625em) {
      .banner-block .cloud-three {
        display: block; } }
  .banner-block .gulls {
    width: 50px;
    height: 35px;
    background: url("../images/Homepage_Banner_Sky_Elements-04.svg") no-repeat center center;
    bottom: 65px;
    right: 75px;
    display: none; }
    @media (min-width: 64.0625em) {
      .banner-block .gulls {
        display: block; } }

.awards-bar {
  width: 100%;
  max-width: 100%;
  background-color: #FFF;
  padding: 25px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }
  @media (min-width: 64.0625em) {
    .awards-bar {
      flex-wrap: nowrap; } }
  .awards-bar h3 {
    color: #323e48;
    margin: 0 0 20px 0;
    flex-basis: 100%;
    text-align: center; }
    @media (min-width: 64.0625em) {
      .awards-bar h3 {
        flex-basis: auto;
        text-align: left;
        margin: 0; } }
  .awards-bar ul {
    max-width: 595px;
    width: 100%;
    height: 55px;
    margin: 0;
    padding: 0;
    display: none; }
    @media (min-width: 64.0625em) {
      .awards-bar ul {
        display: block; } }
    .awards-bar ul.awards-list {
      margin: 0 auto;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 100%;
      flex-wrap: wrap;
      display: flex;
      height: auto; }
      @media (min-width: 64.0625em) {
        .awards-bar ul.awards-list {
          display: none; } }
    .awards-bar ul li {
      list-style-type: none;
      margin: 0 10px 10px;
      height: 50px;
      background-position: center center; }
      @media (min-width: 64.0625em) {
        .awards-bar ul li {
          margin: 0 10px; } }
      .awards-bar ul li.award-0 {
        background-image: url("../images/Proud-finalist-color.png");
        background-repeat: no-repeat;
        width: 125px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-0 {
            background-image: url("../images/Proud-finalist-grey.png"); } }
        .awards-bar ul li.award-0:hover {
          background-image: url("../images/Proud-finalist-color.png");
          width: 125px; }
      .awards-bar ul li.award-01 {
        background-image: url("../images/finalist-brick-colour.png");
        background-repeat: no-repeat;
        width: 102px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-01 {
            background-image: url("../images/finalist-brick-grey.png"); } }
        .awards-bar ul li.award-01:hover {
          background-image: url("../images/finalist-brick-colour.png");
          width: 102px; }
      .awards-bar ul li.award-02 {
        background-image: url("../images/SWPA-2020_SHORTLISTED.png");
        background-repeat: no-repeat;
        width: 67px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-02 {
            background-image: url("../images/SWPA-2020_SHORTLISTED-grey.png"); } }
        .awards-bar ul li.award-02:hover {
          background-image: url("../images/SWPA-2020_SHORTLISTED.png");
          width: 67px; }
      .awards-bar ul li.award-03 {
        background-image: url("../images/bpa-20-winner-colour.png");
        background-repeat: no-repeat;
        width: 102px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-03 {
            background-image: url("../images/bpa-20-winner-grey.png"); } }
        .awards-bar ul li.award-03:hover {
          background-image: url("../images/bpa-20-winner-colour.png");
          width: 102px; }
      .awards-bar ul li.award-04 {
        background-image: url("../images/SWPA-2020_WINNER-color.png");
        background-repeat: no-repeat;
        width: 69px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-04 {
            background-image: url("../images/SWPA-2020_WINNER-gery.png"); } }
        .awards-bar ul li.award-04:hover {
          background-image: url("../images/SWPA-2020_WINNER-color.png");
          width: 69px; }
      .awards-bar ul li.award-05 {
        background-image: url("../images/Bristol-Life-Awards-Finalist-logo-color-21.png");
        background-repeat: no-repeat;
        width: 136px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-05 {
            background-image: url("../images/Bristol-Life-Awards-Finalist-logo-grey-21.png"); } }
        .awards-bar ul li.award-05:hover {
          background-image: url("../images/Bristol-Life-Awards-Finalist-logo-color-21.png");
          width: 136px; }
      .awards-bar ul li.award-1 {
        background-image: url("../images/mortgage-strategy-winner-colour.png");
        background-repeat: no-repeat;
        width: 90px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-1 {
            background-image: url("../images/mortgage-strategy-winner-grey.png"); } }
        .awards-bar ul li.award-1:hover {
          background-image: url("../images/mortgage-strategy-winner-colour.png");
          width: 90px; }
      .awards-bar ul li.award-2 {
        background-image: url("../images/business-leader-awards-colour.png");
        background-repeat: no-repeat;
        width: 92px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-2 {
            background-image: url("../images/business-leader-awards-grey.png"); } }
        .awards-bar ul li.award-2:hover {
          background-image: url("../images/business-leader-awards-colour.png"); }
      .awards-bar ul li.award-3 {
        background-image: url("../images/money-age-awards-colour.png");
        background-repeat: no-repeat;
        width: 87px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-3 {
            background-image: url("../images/money-age-awards-grey.png"); } }
        .awards-bar ul li.award-3:hover {
          background-image: url("../images/money-age-awards-colour.png");
          width: 87px; }
      .awards-bar ul li.award-4 {
        background-image: url("../images/mortgage-strategy-finalist-colour.png");
        background-repeat: no-repeat;
        width: 92px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-4 {
            background-image: url("../images/mortgage-strategy-finalist-grey.png"); } }
        .awards-bar ul li.award-4:hover {
          background-image: url("../images/mortgage-strategy-finalist-colour.png"); }
      .awards-bar ul li.award-5 {
        background-image: url("../images/women-in-business-colour.png");
        background-repeat: no-repeat;
        width: 57px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-5 {
            background-image: url("../images/women-in-business-grey.png"); } }
        .awards-bar ul li.award-5:hover {
          background-image: url("../images/women-in-business-colour.png"); }
      .awards-bar ul li.award-6 {
        background-image: url("../images/woman-of-the-year-colour.png");
        background-repeat: no-repeat;
        width: 57px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-6 {
            background-image: url("../images/woman-of-the-year-grey.png"); } }
        .awards-bar ul li.award-6:hover {
          background-image: url("../images/woman-of-the-year-colour.png"); }
      .awards-bar ul li.award-7 {
        background-image: url("../images/bristol-property-awards-colour.png");
        background-repeat: no-repeat;
        width: 90px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-7 {
            background-image: url("../images/bristol-property-awards-grey.png"); } }
        .awards-bar ul li.award-7:hover {
          background-image: url("../images/bristol-property-awards-colour.png"); }
      .awards-bar ul li.award-8 {
        background-image: url("../images/bristol-life-colour.png");
        background-repeat: no-repeat;
        width: 176px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-8 {
            background-image: url("../images/bristol-life-grey.png"); } }
        .awards-bar ul li.award-8:hover {
          background-image: url("../images/bristol-life-colour.png"); }
      .awards-bar ul li.award-9 {
        background-image: url("../images/bpa-2021-finalist.jpg");
        background-repeat: no-repeat;
        width: 102px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-9 {
            background-image: url("../images/bpa-2021-finalist-grey.jpg"); } }
        .awards-bar ul li.award-9:hover {
          background-image: url("../images/bpa-2021-finalist.jpg"); }
      .awards-bar ul li.award-10 {
        background-image: url("../images/brl-awards-winners-logo.jpg");
        background-repeat: no-repeat;
        width: 125px; }
        @media (min-width: 64.0625em) {
          .awards-bar ul li.award-10 {
            background-image: url("../images/brl-awards-winners-logo-grey.jpg"); } }
        .awards-bar ul li.award-10:hover {
          background-image: url("../images/brl-awards-winners-logo.jpg"); }

.content-block {
  padding: 25px 0; }
  @media (min-width: 64.0625em) {
    .content-block {
      padding: 65px; } }
  .content-block.white {
    background-color: #FFF; }
  .content-block.off-white {
    background-color: #f5f6f6; }
  .content-block.city-bg {
    background: url("../images/city-bg.png") no-repeat bottom left #f5f6f6; }
  .content-block.full {
    width: 100%; }
  .content-block.standard-text p {
    max-width: 685px;
    width: 100%;
    padding: 0 25px;
    margin: 0 auto 25px; }
    @media (min-width: 34.375em) {
      .content-block.standard-text p {
        padding: 0; } }
    .content-block.standard-text p a {
      color: #ea5b0c; }
  .content-block.standard-text a.button {
    text-align: center;
    margin-top: 15px;
    width: auto;
    padding: 13px 10px 0; }
  .content-block.center {
    text-align: center; }
    .content-block.center hr {
      margin: 20px auto; }
  .content-block hr {
    width: 60px;
    height: 2px;
    display: block;
    border: none; }
    .content-block hr.orange {
      background-color: #ea5b0c; }
  .content-block h2, .content-block h3, .content-block h4, .content-block h5 {
    color: #323e48;
    margin: 0; }
  .content-block h3 {
    font-size: 32px; }
    .content-block h3.case-study-title {
      font-size: 28px; }
  .content-block p {
    color: #323e48;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px; }
    .content-block p .head-title {
      font-size: 25px !important;
      font-weight: 700px !important; }
  .content-block.standard-content p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    padding: 0 25px; }
    @media (min-width: 34.375em) {
      .content-block.standard-content p {
        padding: 0 105px; } }
    @media (min-width: 64.0625em) {
      .content-block.standard-content p {
        padding: 0; } }
    .content-block.standard-content p:last-of-type {
      margin-bottom: 0px; }
    .content-block.standard-content p a {
      color: #ea5b0c; }
    .content-block.standard-content p span.blog-date {
      font-size: 14px;
      color: #323e48;
      font-weight: 600; }
  .content-block.standard-content h2 {
    margin-bottom: 15px;
    text-align: center; }
    @media (min-width: 64.0625em) {
      .content-block.standard-content h2 {
        text-align: left; } }
  .content-block.standard-content ul, .content-block.standard-content ol {
    padding: 0 45px; }
    @media (min-width: 34.375em) {
      .content-block.standard-content ul, .content-block.standard-content ol {
        padding: 0 115px; } }
    @media (min-width: 64.0625em) {
      .content-block.standard-content ul, .content-block.standard-content ol {
        padding: 0 0 0 20px; } }
    .content-block.standard-content ul li, .content-block.standard-content ol li {
      color: #323e48;
      margin-bottom: 5px; }
      .content-block.standard-content ul li:last-of-type, .content-block.standard-content ol li:last-of-type {
        margin-bottom: 0px; }
      .content-block.standard-content ul li a, .content-block.standard-content ol li a {
        color: #ea5b0c; }
    .content-block.standard-content ul.team-list, .content-block.standard-content ol.team-list {
      padding: 25px;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      @media (min-width: 64.0625em) {
        .content-block.standard-content ul.team-list, .content-block.standard-content ol.team-list {
          padding: 0;
          margin: 0; } }
      .content-block.standard-content ul.team-list li, .content-block.standard-content ol.team-list li {
        list-style-type: none;
        margin-bottom: 15px;
        flex-basis: 100%;
        padding: 0 25px;
        display: flex;
        flex-wrap: wrap; }
        @media (min-width: 34.375em) {
          .content-block.standard-content ul.team-list li, .content-block.standard-content ol.team-list li {
            flex-wrap: nowrap; } }
        .content-block.standard-content ul.team-list li:last-of-type, .content-block.standard-content ol.team-list li:last-of-type {
          margin-bottom: 0px; }
        .content-block.standard-content ul.team-list li .image-wrap, .content-block.standard-content ol.team-list li .image-wrap {
          width: 100%;
          margin: 0 auto;
          max-height: 390px;
          overflow: hidden; }
          @media (min-width: 34.375em) {
            .content-block.standard-content ul.team-list li .image-wrap, .content-block.standard-content ol.team-list li .image-wrap {
              flex-basis: 30%;
              max-width: 30%;
              margin: 0; } }
          .content-block.standard-content ul.team-list li .image-wrap img, .content-block.standard-content ol.team-list li .image-wrap img {
            width: 100%; }
        .content-block.standard-content ul.team-list li .content, .content-block.standard-content ol.team-list li .content {
          width: 100%;
          text-align: center; }
          @media (min-width: 34.375em) {
            .content-block.standard-content ul.team-list li .content, .content-block.standard-content ol.team-list li .content {
              flex-basis: 70%;
              max-width: 70%;
              text-align: left;
              padding-left: 25px; } }
          .content-block.standard-content ul.team-list li .content h2, .content-block.standard-content ol.team-list li .content h2 {
            font-size: 26px;
            text-align: center; }
            @media (min-width: 34.375em) {
              .content-block.standard-content ul.team-list li .content h2, .content-block.standard-content ol.team-list li .content h2 {
                text-align: left; } }
          .content-block.standard-content ul.team-list li .content p, .content-block.standard-content ol.team-list li .content p {
            font-size: 14px;
            line-height: 24px;
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;
            display: block; }
            .content-block.standard-content ul.team-list li .content p.title, .content-block.standard-content ol.team-list li .content p.title {
              display: block;
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 15px; }
            @media (min-width: 34.375em) {
              .content-block.standard-content ul.team-list li .content p, .content-block.standard-content ol.team-list li .content p {
                display: block; } }
  .content-block.standard-content a.button {
    text-align: center;
    margin-top: 15px;
    width: auto;
    padding: 13px 10px 0; }
  .content-block.standard-cta {
    padding: 70px 20px; }
    @media (min-width: 64.0625em) {
      .content-block.standard-cta {
        padding: 105px 20px; } }
    .content-block.standard-cta ul {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; }
      @media (min-width: 64.0625em) {
        .content-block.standard-cta ul {
          flex-wrap: nowrap; } }
      .content-block.standard-cta ul li {
        list-style-type: none;
        flex-basis: 100%;
        max-width: 100%;
        height: auto;
        width: 100%;
        margin-bottom: 25px;
        border-radius: 10px;
        border: 1px solid #edeeef;
        padding: 30px 20px;
        background-color: #FFF; }
        @media (min-width: 34.375em) {
          .content-block.standard-cta ul li {
            flex-basis: 48%;
            max-width: 48%;
            height: auto; } }
        @media (min-width: 64.0625em) {
          .content-block.standard-cta ul li {
            flex-basis: 255px;
            max-width: 255px;
            height: 410px;
            margin-bottom: 0; } }
        .content-block.standard-cta ul li .image-wrap {
          width: 100%;
          height: 165px;
          overflow: hidden; }
        .content-block.standard-cta ul li .content-wrap {
          background-color: #FFF;
          padding: 10px; }
        .content-block.standard-cta ul li h4 {
          color: #ea5b0c;
          font-size: 22px;
          font-weight: 600;
          margin: 0 0 10px; }
        .content-block.standard-cta ul li p {
          font-size: 16px;
          line-height: 23px;
          margin-bottom: 15px;
          height: 80px; }
  .content-block.simple-cta {
    padding: 30px 20px; }
    @media (min-width: 34.375em) {
      .content-block.simple-cta {
        padding: 70px 90px; } }
    @media (min-width: 64.0625em) {
      .content-block.simple-cta {
        padding: 105px 90px; } }
    .content-block.simple-cta ul {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      @media (min-width: 64.0625em) {
        .content-block.simple-cta ul {
          flex-wrap: nowrap; } }
      .content-block.simple-cta ul li {
        flex-basis: 100%;
        max-width: 100%;
        width: 100%;
        padding: 15px;
        list-style-type: none;
        background-color: #f5f6f6;
        flex-wrap: nowrap;
        margin-bottom: 20px; }
        @media (min-width: 34.375em) {
          .content-block.simple-cta ul li {
            flex-basis: 22%;
            max-width: 22%;
            margin-bottom: 0px; } }
        @media (min-width: 64.0625em) {
          .content-block.simple-cta ul li {
            flex-basis: 220px;
            max-width: 220px; } }
        .content-block.simple-cta ul li a {
          text-decoration: none;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
          height: 72px; }
          @media (min-width: 64.0625em) {
            .content-block.simple-cta ul li a {
              flex-wrap: nowrap; } }
        .content-block.simple-cta ul li .image-wrap {
          flex-basis: 100%;
          text-align: center;
          height: 61px; }
          @media (min-width: 34.375em) {
            .content-block.simple-cta ul li .image-wrap {
              text-align: left; } }
          @media (min-width: 64.0625em) {
            .content-block.simple-cta ul li .image-wrap {
              flex-basis: 32%; } }
        .content-block.simple-cta ul li h4 {
          text-align: center;
          font-size: 18px;
          margin: 0;
          line-height: 24px;
          flex-basis: 100%; }
          @media (min-width: 34.375em) {
            .content-block.simple-cta ul li h4 {
              text-align: left;
              flex-basis: 90%; } }
          @media (min-width: 64.0625em) {
            .content-block.simple-cta ul li h4 {
              flex-basis: 60%; } }
  .content-block.testimonials ul.testimonials-inner {
    padding: 0;
    margin: 0;
    width: 100%;
    position: relative; }
    .content-block.testimonials ul.testimonials-inner li {
      width: 100%;
      text-align: center;
      height: auto;
      padding: 0 45px; }
      .content-block.testimonials ul.testimonials-inner li p {
        max-width: 665px;
        width: 100%;
        margin: 0 auto 15px;
        font-size: 18px;
        line-height: 26px; }
      .content-block.testimonials ul.testimonials-inner li span {
        color: #ea5b0c;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        display: block;
        margin-bottom: 40px; }
    .content-block.testimonials ul.testimonials-inner .slick-prev,
    .content-block.testimonials ul.testimonials-inner .slick-next {
      width: 45px;
      height: 60px;
      border-radius: 5px;
      color: transparent;
      border: none;
      position: absolute;
      top: 42%;
      background-color: #FFF;
      background-repeat: no-repeat;
      background-position: center center; }
    .content-block.testimonials ul.testimonials-inner .slick-prev {
      left: 0px;
      background-image: url("../images/testimonail-prev-arrow.png"); }
    .content-block.testimonials ul.testimonials-inner .slick-next {
      right: 0px;
      background-image: url("../images/testimonail-next-arrow.png"); }
  .content-block.left-align-content {
    padding: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
    @media (min-width: 34.375em) {
      .content-block.left-align-content {
        padding: 65px;
        flex-wrap: nowrap; } }
    .content-block.left-align-content hr {
      margin: 20px 0; }
    .content-block.left-align-content .content {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%; }
      .content-block.left-align-content .content.with-thumb {
        flex-basis: 645px;
        width: 100%;
        max-width: 645px; }
      .content-block.left-align-content .content h2 {
        margin-bottom: 15px; }
      .content-block.left-align-content .content p {
        font-size: 16px;
        margin-bottom: 10px; }
        .content-block.left-align-content .content p:last-of-type {
          margin-bottom: 0px; }
        .content-block.left-align-content .content p a {
          color: #ea5b0c; }
      .content-block.left-align-content .content a.button {
        text-align: center;
        margin-top: 15px;
        width: auto;
        padding: 13px 10px 0; }
      .content-block.left-align-content .content ul li, .content-block.left-align-content .content ol li {
        color: #323e48; }
        .content-block.left-align-content .content ul li a, .content-block.left-align-content .content ol li a {
          color: #ea5b0c; }
    .content-block.left-align-content .feat-img {
      flex-basis: 195px;
      width: 100%;
      max-width: 195px;
      margin: 30px auto 0; }
      @media (min-width: 34.375em) {
        .content-block.left-align-content .feat-img {
          margin: 0; } }
      .content-block.left-align-content .feat-img img {
        width: 100%; }
  .content-block.accordion-content {
    padding: 0; }
    @media (min-width: 34.375em) {
      .content-block.accordion-content {
        padding: 55px 0; } }
    .content-block.accordion-content .accordion-item {
      width: 100%; }
      .content-block.accordion-content .accordion-item .accordion-title {
        width: 100%;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer; }
        @media (min-width: 34.375em) {
          .content-block.accordion-content .accordion-item .accordion-title {
            padding: 30px 90px; } }
        .content-block.accordion-content .accordion-item .accordion-title h2 {
          font-size: 16px;
          padding-right: 20px;
          flex-basis: 90%; }
          @media (min-width: 34.375em) {
            .content-block.accordion-content .accordion-item .accordion-title h2 {
              font-size: 28px; } }
        .content-block.accordion-content .accordion-item .accordion-title .block-open {
          width: 26px;
          height: 26px;
          flex-basis: 26px;
          cursor: pointer; }
          .content-block.accordion-content .accordion-item .accordion-title .block-open .open {
            display: block; }
          .content-block.accordion-content .accordion-item .accordion-title .block-open .close {
            display: none; }
        .content-block.accordion-content .accordion-item .accordion-title.active .open {
          display: none; }
        .content-block.accordion-content .accordion-item .accordion-title.active .close {
          display: block; }
      .content-block.accordion-content .accordion-item .accordion-block {
        width: 100%;
        border-top: 2px solid #edeeef;
        height: 2px;
        overflow: hidden; }
        .content-block.accordion-content .accordion-item .accordion-block.active {
          height: auto;
          padding: 35px;
          background: #FFF; }
          @media (min-width: 34.375em) {
            .content-block.accordion-content .accordion-item .accordion-block.active {
              padding: 35px 90px; } }
          .content-block.accordion-content .accordion-item .accordion-block.active p {
            font-size: 16px;
            margin-bottom: 20px; }
            .content-block.accordion-content .accordion-item .accordion-block.active p:last-of-type {
              margin-bottom: 0px; }
            .content-block.accordion-content .accordion-item .accordion-block.active p a {
              color: #ea5b0c; }
          .content-block.accordion-content .accordion-item .accordion-block.active a.button {
            color: #FFF; }
          .content-block.accordion-content .accordion-item .accordion-block.active table tr td {
            padding: 10px;
            color: #323e48; }
            .content-block.accordion-content .accordion-item .accordion-block.active table tr td strong {
              color: #323e48; }
          .content-block.accordion-content .accordion-item .accordion-block.active ul li {
            color: #323e48;
            margin-bottom: 5px; }
            .content-block.accordion-content .accordion-item .accordion-block.active ul li:last-of-type {
              margin-bottom: 0px; }
            .content-block.accordion-content .accordion-item .accordion-block.active ul li a {
              color: #ea5b0c; }
          .content-block.accordion-content .accordion-item .accordion-block.active .block-close-wrap {
            width: 100%;
            position: relative;
            margin-top: 40px;
            height: 40px; }
            .content-block.accordion-content .accordion-item .accordion-block.active .block-close-wrap .block-close {
              position: absolute;
              right: 0px;
              top: 0px;
              width: 110px;
              height: 40px;
              color: #ea5b0c;
              border-radius: 5px;
              border: 1px solid #ea5b0c;
              background: #FFF;
              text-align: left;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
              padding: 9px 0 0 15px;
              cursor: pointer;
              background: url("../images/accordion-close.png") no-repeat 85% 45%; }
      .content-block.accordion-content .accordion-item:last-of-type .accordion-block {
        border-top: none; }
  .content-block.after-accordion {
    padding: 65px 35px;
    margin-bottom: 65px; }
    @media (min-width: 34.375em) {
      .content-block.after-accordion {
        padding: 65px; } }
    .content-block.after-accordion h3 {
      font-size: 20px; }
    .content-block.after-accordion p {
      font-size: 16px;
      margin-bottom: 15px; }
      .content-block.after-accordion p iframe {
        border: none;
        margin: 0 auto;
        display: block;
        max-width: 100%;
        height: 150px; }
        @media (min-width: 20.3125em) {
          .content-block.after-accordion p iframe {
            height: 336px; } }
      .content-block.after-accordion p:last-of-type {
        margin-bottom: 0px; }
    .content-block.after-accordion a {
      color: #ea5b0c; }
      .content-block.after-accordion a.button {
        text-align: center;
        margin: 15px 0 0 0;
        width: auto;
        padding: 13px 10px 0; }
    .content-block.after-accordion ul li, .content-block.after-accordion ol li {
      color: #323e48;
      margin-bottom: 5px; }
      .content-block.after-accordion ul li:last-of-type, .content-block.after-accordion ol li:last-of-type {
        margin-bottom: 0px; }
      .content-block.after-accordion ul li a, .content-block.after-accordion ol li a {
        color: #ea5b0c; }
  .content-block.blog-snippet {
    padding: 70px 20px; }
    @media (min-width: 64.0625em) {
      .content-block.blog-snippet {
        padding: 105px 20px; } }
    .content-block.blog-snippet ul {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
      flex-wrap: wrap; }
      @media (min-width: 64.0625em) {
        .content-block.blog-snippet ul {
          justify-content: space-between;
          flex-wrap: nowrap; } }
      .content-block.blog-snippet ul .home-post {
        background-color: #f5f6f6;
        padding: 25px;
        margin-bottom: 25px;
        list-style-type: none;
        flex-basis: 100%; }
        @media (min-width: 34.375em) {
          .content-block.blog-snippet ul .home-post {
            flex-basis: 32%; } }
        .content-block.blog-snippet ul .home-post a {
          text-decoration: none; }
          .content-block.blog-snippet ul .home-post a span {
            color: #323e48; }
          .content-block.blog-snippet ul .home-post a .button {
            width: 145px;
            height: 45px;
            background-color: #ea5b0c;
            color: #FFF;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
            padding-top: 12px;
            margin: 15px auto 0; }
  .content-block.calculator-forms {
    padding: 30px; }
    @media (min-width: 64.0625em) {
      .content-block.calculator-forms {
        padding: 65px; } }
    .content-block.calculator-forms .calculator-container {
      width: 100%; }
      .content-block.calculator-forms .calculator-container form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
        @media (min-width: 34.375em) {
          .content-block.calculator-forms .calculator-container form {
            padding: 0; } }
        @media (min-width: 64.0625em) {
          .content-block.calculator-forms .calculator-container form {
            flex-wrap: nowrap; } }
        .content-block.calculator-forms .calculator-container form .col2 {
          flex-basis: 100%;
          max-width: 100%;
          width: 100%;
          margin-bottom: 25px; }
          @media (min-width: 64.0625em) {
            .content-block.calculator-forms .calculator-container form .col2 {
              flex-basis: 45%;
              max-width: 45%;
              margin-bottom: 0; } }
          .content-block.calculator-forms .calculator-container form .col2 table {
            width: 100%; }
            .content-block.calculator-forms .calculator-container form .col2 table tbody tr td .label {
              width: 190px;
              color: #323e48; }
            .content-block.calculator-forms .calculator-container form .col2 table tbody tr td h3 {
              color: #ea5b0c;
              margin-bottom: 15px; }
            .content-block.calculator-forms .calculator-container form .col2 table tbody tr td .input-container {
              position: relative;
              padding: 10px 0; }
              .content-block.calculator-forms .calculator-container form .col2 table tbody tr td .input-container input {
                width: 100%;
                border: 1px solid #ea5b0c;
                padding: 0 0 0 30px;
                color: #ea5b0c;
                height: 50px;
                background-color: #fdefe7; }
            .content-block.calculator-forms .calculator-container form .col2 table tbody tr td .poundsign {
              color: #ea5b0c;
              position: absolute;
              left: 10px;
              top: 20px;
              font-size: 18px;
              font-weight: 600; }
            .content-block.calculator-forms .calculator-container form .col2 table tbody tr td .percentage {
              color: #ea5b0c;
              position: absolute;
              right: 10px;
              top: 20px;
              font-size: 18px;
              font-weight: 600; }
            .content-block.calculator-forms .calculator-container form .col2 table tbody tr td .years {
              color: #ea5b0c;
              position: absolute;
              right: 10px;
              top: 20px;
              font-size: 18px;
              font-weight: 600; }
            .content-block.calculator-forms .calculator-container form .col2 table tbody tr td .calc_button {
              width: 120px;
              height: 40px;
              color: #FFF;
              background-color: #ea5b0c;
              text-transform: uppercase;
              border-radius: 5px;
              font-size: 14px;
              font-weight: 600;
              border: 0; }
  .content-block.contact-form form {
    max-width: 680px;
    width: 100%;
    padding: 0 25px;
    margin: 25px auto 0; }
    @media (min-width: 34.375em) {
      .content-block.contact-form form {
        margin: 35px auto 0;
        padding: 0; } }
    .content-block.contact-form form .form-row {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between; }
      .content-block.contact-form form .form-row:last-of-type {
        margin-bottom: 0px; }
      .content-block.contact-form form .form-row .control-group {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%; }
      .content-block.contact-form form .form-row input {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
        background-color: #f5f6f6;
        border: 1px solid #f3f4f4;
        height: 45px;
        padding-left: 15px;
        color: #323e48; }
        .content-block.contact-form form .form-row input::placeholder {
          color: #323e48 !important; }
      .content-block.contact-form form .form-row select {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
        background-color: #f5f6f6;
        border: 1px solid #f3f4f4;
        height: 45px;
        border-radius: 0px;
        color: #323e48;
        padding-left: 15px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url("../images/down-pointer.png");
        background-position: 95% 50%;
        background-repeat: no-repeat;
        background-size: 12px; }
      .content-block.contact-form form .form-row textarea {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
        background-color: #f5f6f6;
        border: 1px solid #f3f4f4;
        height: 130px;
        padding: 15px 0 0 15px;
        color: #323e48; }
        .content-block.contact-form form .form-row textarea::placeholder {
          color: #323e48 !important; }
      .content-block.contact-form form .form-row label {
        display: none; }
      .content-block.contact-form form .form-row.two-input {
        flex-wrap: wrap; }
        @media (min-width: 34.375em) {
          .content-block.contact-form form .form-row.two-input {
            flex-wrap: nowrap; } }
        .content-block.contact-form form .form-row.two-input .control-group:nth-child(2) {
          padding: 0;
          margin-top: 20px; }
          @media (min-width: 34.375em) {
            .content-block.contact-form form .form-row.two-input .control-group:nth-child(2) {
              padding-left: 15px;
              margin-top: 0px; } }
    .content-block.contact-form form button {
      border-radius: 5px;
      background-color: #ea5b0c;
      color: #FFF;
      width: 170px;
      height: 40px;
      padding: 0;
      font-weight: 600;
      font-size: 14px; }
  .content-block.location-info {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    flex-wrap: wrap; }
    @media (min-width: 64.0625em) {
      .content-block.location-info {
        flex-wrap: nowrap;
        padding: 65px 65px 30px 65px; } }
    .content-block.location-info .left {
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
      padding: 25px; }
      @media (min-width: 64.0625em) {
        .content-block.location-info .left {
          flex-basis: 50%;
          max-width: 50%;
          width: 100%;
          padding: 0; } }
      .content-block.location-info .left h2 {
        font-size: 32px;
        margin-bottom: 25px; }
      .content-block.location-info .left h3 {
        font-size: 25px; }
      .content-block.location-info .left p {
        margin-bottom: 10px;
        font-size: 16px; }
        .content-block.location-info .left p a {
          color: #ea5b0c; }
    .content-block.location-info .right {
      flex-basis: 100%;
      max-width: 100%;
      width: 100%; }
      @media (min-width: 64.0625em) {
        .content-block.location-info .right {
          flex-basis: 50%;
          max-width: 50%;
          width: 100%;
          padding: 0; } }
      .content-block.location-info .right iframe {
        width: 100%; }
  .content-block.blog-list ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .content-block.blog-list ul li {
      list-style-type: none;
      flex-basis: 100%;
      width: 100%;
      max-width: 100%;
      background-color: #f5f6f6;
      padding: 25px;
      margin-bottom: 15px; }
      .content-block.blog-list ul li a {
        text-decoration: none; }
        .content-block.blog-list ul li a span.case-study-title {
          font-size: 28px; }
        .content-block.blog-list ul li a hr {
          margin: 15px 0; }
        .content-block.blog-list ul li a span {
          font-size: 14px;
          color: #323e48;
          font-weight: 600; }
        .content-block.blog-list ul li a .button {
          width: 145px;
          height: 45px;
          background-color: #ea5b0c;
          color: #FFF;
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          padding-top: 12px;
          margin-top: 15px; }
      @media (min-width: 34.375em) {
        .content-block.blog-list ul li {
          flex-basis: 48%;
          width: 100%;
          max-width: 48%; } }
  .content-block.blog-list .tool-pagination {
    width: 100%; }
    .content-block.blog-list .tool-pagination ul {
      padding: 0;
      margin: 40px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .content-block.blog-list .tool-pagination ul li {
        flex-basis: auto;
        width: auto;
        background-color: #FFF;
        margin: 0;
        padding: 0 15px; }
        .content-block.blog-list .tool-pagination ul li a {
          color: #323e48; }
        .content-block.blog-list .tool-pagination ul li .current {
          color: #ea5b0c; }
        .content-block.blog-list .tool-pagination ul li.btn {
          background-color: #ea5b0c;
          padding: 5px 15px;
          border-radius: 5px; }
          .content-block.blog-list .tool-pagination ul li.btn a {
            color: #FFF; }
  .content-block.blog-list-columns {
    display: flex;
    flex-direction: column; }
    @media (min-width: 48.125em) {
      .content-block.blog-list-columns {
        flex-direction: row; } }
    .content-block.blog-list-columns .col-4 {
      padding: 0px 25px 25px 25px;
      width: 100% !important; }
      @media (min-width: 48.125em) {
        .content-block.blog-list-columns .col-4 {
          width: 33% !important; } }
      .content-block.blog-list-columns .col-4 .blog-list-categories-container {
        background: #f5f6f6;
        padding: 25px 10px 10px 10px; }
        .content-block.blog-list-columns .col-4 .blog-list-categories-container .blog-list-categories {
          display: none; }
          @media (min-width: 48.125em) {
            .content-block.blog-list-columns .col-4 .blog-list-categories-container .blog-list-categories {
              display: block; } }
        .content-block.blog-list-columns .col-4 .blog-list-categories-container .blog-list-categories-dropdown {
          width: 100% !important;
          margin-top: 15px;
          padding: 5px 10px 5px 10px;
          border: 1px solid #ea5b0c;
          color: #ea5b0c;
          background: white;
          display: block; }
          @media (min-width: 48.125em) {
            .content-block.blog-list-columns .col-4 .blog-list-categories-container .blog-list-categories-dropdown {
              display: none; } }
        .content-block.blog-list-columns .col-4 .blog-list-categories-container a {
          width: 100%;
          display: block;
          padding: 5px 10px 5px 10px;
          text-decoration: none !important;
          color: #333;
          font-size: 14px; }
          .content-block.blog-list-columns .col-4 .blog-list-categories-container a:hover {
            background: #ea5b0c;
            color: white;
            cursor: pointer; }
          .content-block.blog-list-columns .col-4 .blog-list-categories-container a.active {
            background: #ea5b0c;
            color: white; }
    .content-block.blog-list-columns .col-8 {
      width: 100% !important; }
      @media (min-width: 48.125em) {
        .content-block.blog-list-columns .col-8 {
          width: 66% !important; } }
  .content-block.bullets p {
    font-size: 16px;
    padding: 0 25px; }
  .content-block.bullets ul {
    margin: 25px 0 0;
    width: 100%;
    max-width: 100%;
    padding: 0 65px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (min-width: 34.375em) {
      .content-block.bullets ul {
        padding: 0 50px; } }
    .content-block.bullets ul li {
      color: #ea5b0c;
      list-style-position: outside;
      margin-bottom: 10px;
      text-align: left;
      flex-basis: 100%;
      width: 100%;
      font-size: 20px; }
      @media (min-width: 34.375em) {
        .content-block.bullets ul li {
          flex-basis: 50%;
          list-style-position: inside;
          margin-bottom: 0px; } }
      .content-block.bullets ul li span {
        color: #323e48;
        font-size: 16px; }
  .content-block.cards-wrap .cards-standard-content {
    padding: 0 45px; }
    @media (min-width: 34.375em) {
      .content-block.cards-wrap .cards-standard-content {
        padding: 65px; } }
    .content-block.cards-wrap .cards-standard-content ul, .content-block.cards-wrap .cards-standard-content ol {
      padding: 0 20px;
      margin-bottom: 25px; }
      @media (min-width: 34.375em) {
        .content-block.cards-wrap .cards-standard-content ul, .content-block.cards-wrap .cards-standard-content ol {
          padding: 0 115px; } }
      @media (min-width: 64.0625em) {
        .content-block.cards-wrap .cards-standard-content ul, .content-block.cards-wrap .cards-standard-content ol {
          padding: 0 0 0 20px; } }
      .content-block.cards-wrap .cards-standard-content ul li, .content-block.cards-wrap .cards-standard-content ol li {
        color: #323e48;
        margin-bottom: 5px; }
        .content-block.cards-wrap .cards-standard-content ul li:last-of-type, .content-block.cards-wrap .cards-standard-content ol li:last-of-type {
          margin-bottom: 0px; }
        .content-block.cards-wrap .cards-standard-content ul li a, .content-block.cards-wrap .cards-standard-content ol li a {
          color: #ea5b0c; }
  .content-block.cards-wrap ul.card-list {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap; }
    @media (min-width: 48.125em) {
      .content-block.cards-wrap ul.card-list {
        flex-wrap: nowrap;
        justify-content: center; } }
    .content-block.cards-wrap ul.card-list li.cards {
      flex-basis: 260px;
      list-style-type: none;
      text-align: center;
      margin-bottom: 20px; }
      @media (min-width: 48.125em) {
        .content-block.cards-wrap ul.card-list li.cards {
          margin-bottom: 0px; } }
      .content-block.cards-wrap ul.card-list li.cards .card-title {
        background-color: #ea5b0c;
        color: #FFF;
        padding: 20px;
        font-weight: 600;
        font-size: 20px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px; }
      .content-block.cards-wrap ul.card-list li.cards .card-content {
        padding: 30px 10px;
        background-color: #f5f6f6; }
      .content-block.cards-wrap ul.card-list li.cards .card-bottom {
        background-color: #f5f6f6;
        padding: 20px 20px 40px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px; }
        .content-block.cards-wrap ul.card-list li.cards .card-bottom .button {
          width: 150px;
          background-color: #ea5b0c;
          padding: 13px 15px 15px;
          color: #FFF;
          display: block;
          margin: 0 auto;
          font-weight: 600;
          text-decoration: none; }
      @media (min-width: 48.125em) {
        .content-block.cards-wrap ul.card-list li.cards:first-of-type .card-title {
          border-top-left-radius: 8px;
          border-top-right-radius: 0px; }
        .content-block.cards-wrap ul.card-list li.cards:first-of-type .card-bottom {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 0px; }
        .content-block.cards-wrap ul.card-list li.cards:nth-child(2) {
          flex-basis: 340px;
          -webkit-box-shadow: 0px 5px 20px -1px rgba(0, 0, 0, 0.35);
          -moz-box-shadow: 0px 5px 20px -1px rgba(0, 0, 0, 0.35);
          box-shadow: 0px 5px 20px -1px rgba(0, 0, 0, 0.35);
          position: relative; }
          .content-block.cards-wrap ul.card-list li.cards:nth-child(2) .card-title {
            padding: 30px 10px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px; }
          .content-block.cards-wrap ul.card-list li.cards:nth-child(2) .card-content {
            height: 340px; }
          .content-block.cards-wrap ul.card-list li.cards:nth-child(2) .card-bottom {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px; }
        .content-block.cards-wrap ul.card-list li.cards:last-of-type .card-title {
          border-top-right-radius: 8px;
          border-top-left-radius: 0px; }
        .content-block.cards-wrap ul.card-list li.cards:last-of-type .card-bottom {
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 0px; } }
  .content-block .calculator-container {
    width: 100%; }
    .content-block .calculator-container form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      @media (min-width: 34.375em) {
        .content-block .calculator-container form {
          padding: 0; } }
      @media (min-width: 64.0625em) {
        .content-block .calculator-container form {
          flex-wrap: nowrap; } }
      .content-block .calculator-container form .col2 {
        flex-basis: 100%;
        max-width: 100%;
        width: 100%;
        margin-bottom: 25px; }
        @media (min-width: 64.0625em) {
          .content-block .calculator-container form .col2 {
            flex-basis: 45%;
            max-width: 45%;
            margin-bottom: 0; } }
        .content-block .calculator-container form .col2 table {
          width: 100%; }
          .content-block .calculator-container form .col2 table tbody tr td .label {
            width: 190px;
            color: #323e48; }
          .content-block .calculator-container form .col2 table tbody tr td h3 {
            color: #ea5b0c;
            margin-bottom: 15px; }
          .content-block .calculator-container form .col2 table tbody tr td .input-container {
            position: relative;
            padding: 10px 0; }
            .content-block .calculator-container form .col2 table tbody tr td .input-container input {
              width: 100%;
              border: 1px solid #ea5b0c;
              padding: 0 0 0 30px;
              color: #ea5b0c;
              height: 50px;
              background-color: #fdefe7; }
          .content-block .calculator-container form .col2 table tbody tr td .poundsign {
            color: #ea5b0c;
            position: absolute;
            left: 10px;
            top: 20px;
            font-size: 18px;
            font-weight: 600; }
          .content-block .calculator-container form .col2 table tbody tr td .percentage {
            color: #ea5b0c;
            position: absolute;
            right: 10px;
            top: 20px;
            font-size: 18px;
            font-weight: 600; }
          .content-block .calculator-container form .col2 table tbody tr td .years {
            color: #ea5b0c;
            position: absolute;
            right: 10px;
            top: 20px;
            font-size: 18px;
            font-weight: 600; }
          .content-block .calculator-container form .col2 table tbody tr td .calc_button {
            width: 120px;
            height: 40px;
            color: #FFF;
            background-color: #ea5b0c;
            text-transform: uppercase;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 600;
            border: 0; }
  .content-block.alt-block-content {
    padding: 50px; }
    .content-block.alt-block-content ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap; }
      .content-block.alt-block-content ul li {
        list-style-type: none;
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        flex-wrap: wrap; }
        .content-block.alt-block-content ul li .image {
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          max-width: 100%;
          width: 100%;
          padding: 100px; }
          @media (min-width: 34.375em) {
            .content-block.alt-block-content ul li .image {
              max-width: 48%;
              padding: 0px; } }
        .content-block.alt-block-content ul li .content {
          max-width: 100%;
          width: 100%;
          padding: 25px; }
          @media (min-width: 34.375em) {
            .content-block.alt-block-content ul li .content {
              max-width: 48%; } }
        .content-block.alt-block-content ul li:nth-child(even) .image {
          order: 1; }
          @media (min-width: 34.375em) {
            .content-block.alt-block-content ul li:nth-child(even) .image {
              order: 2; } }
        .content-block.alt-block-content ul li:nth-child(even) .content {
          order: 2; }
          @media (min-width: 34.375em) {
            .content-block.alt-block-content ul li:nth-child(even) .content {
              order: 1; } }
        .content-block.alt-block-content ul li:last-of-type {
          margin-bottom: 0px; }

.page-template-template-contact #contact-us {
  display: none; }

.page-template-template-contact .right.contact-form .form-row {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between; }
  .page-template-template-contact .right.contact-form .form-row:last-of-type {
    margin-bottom: 0px; }
  .page-template-template-contact .right.contact-form .form-row .control-group {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .page-template-template-contact .right.contact-form .form-row input {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
    background-color: #f5f6f6;
    border: 1px solid #f3f4f4;
    height: 45px;
    padding-left: 15px;
    color: #323e48; }
    .page-template-template-contact .right.contact-form .form-row input::placeholder {
      color: #323e48 !important; }
  .page-template-template-contact .right.contact-form .form-row select {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
    background-color: #f5f6f6;
    border: 1px solid #f3f4f4;
    height: 45px;
    border-radius: 0px;
    color: #323e48;
    padding-left: 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("../images/down-pointer.png");
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 12px; }
  .page-template-template-contact .right.contact-form .form-row textarea {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
    background-color: #f5f6f6;
    border: 1px solid #f3f4f4;
    height: 130px;
    padding: 15px 0 0 15px;
    color: #323e48; }
    .page-template-template-contact .right.contact-form .form-row textarea::placeholder {
      color: #323e48 !important; }
  .page-template-template-contact .right.contact-form .form-row label {
    display: none; }
  .page-template-template-contact .right.contact-form .form-row.two-input {
    flex-wrap: wrap; }
    @media (min-width: 34.375em) {
      .page-template-template-contact .right.contact-form .form-row.two-input {
        flex-wrap: nowrap; } }
    .page-template-template-contact .right.contact-form .form-row.two-input .control-group:nth-child(2) {
      padding: 0;
      margin-top: 20px; }
      @media (min-width: 34.375em) {
        .page-template-template-contact .right.contact-form .form-row.two-input .control-group:nth-child(2) {
          padding-left: 15px;
          margin-top: 0px; } }

.page-template-template-contact .right.contact-form button {
  border-radius: 5px;
  background-color: #ea5b0c;
  color: #FFF;
  width: 170px;
  height: 40px;
  padding: 0;
  font-weight: 600;
  font-size: 14px; }

.page-template-template-contact .right p {
  font-size: 11px;
  font-weight: 400;
  margin-top: 10px;
  text-align: left;
  line-height: 18px;
  padding-right: 25px; }
  .page-template-template-contact .right p:first-of-type {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px; }
  .page-template-template-contact .right p a {
    color: #ea5b0c; }

.page-template-template-contact .gmap-wrap {
  margin-bottom: 25px; }

.page-template-template-contact .content-block.contact-form {
  padding: 40px 40px 0; }
  .page-template-template-contact .content-block.contact-form p {
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    padding: 0 25px;
    text-align: left; }
    .page-template-template-contact .content-block.contact-form p:last-of-type {
      margin-bottom: 0px; }
    .page-template-template-contact .content-block.contact-form p a {
      color: #ea5b0c; }
  .page-template-template-contact .content-block.contact-form form {
    margin: 0 auto 25px; }

.feat-img {
  margin-bottom: 20px; }

.casestudy-points {
  margin: 10px 0 20px; }

.advisor-details {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  display: flex; }
  .advisor-details .advisor-img {
    max-width: 260px;
    width: 100%;
    margin-right: 25px; }

.head-title {
  font-size: 25px !important;
  font-weight: 700 !important; }

.content-block img {
  height: auto !important; }

.popup-block {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #FFF;
  border-top: 2px solid #ea5b0c;
  padding: 30px 15px 15px;
  z-index: 9999; }
  @media (min-width: 34.375em) {
    .popup-block {
      padding: 15px; } }
  .popup-block .inner {
    max-width: 620px;
    margin: 0 auto;
    text-align: center; }
  .popup-block .close-popup {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 14px;
    letter-spacing: 2px;
    color: #ea5b0c;
    cursor: pointer; }
    @media (min-width: 34.375em) {
      .popup-block .close-popup {
        top: 15px;
        right: 15px;
        font-size: 16px; } }
    .popup-block .close-popup .mfp-close {
      position: relative;
      vertical-align: middle;
      display: inline-block;
      border: 1px solid white;
      border-radius: 100%;
      color: #ea5b0c;
      width: 26px;
      height: 26px;
      line-height: 23px; }
  .popup-block h2 {
    font-weight: 600;
    margin: 0;
    color: #323e48;
    font-size: 16px; }
    @media (min-width: 34.375em) {
      .popup-block h2 {
        font-size: 25px; } }

/* 8 */
/*  ==========================================================================
    Generic:Widths
    ========================================================================== */
/*
    Sizes in human readable format. These are used in conjunction with other
    objects and abstractions, most commonly the grid system.

    We have a mixin to generate our widths and their breakpoint-specific
    variations.
*/
/*
        Whole
        -----
    */
.one-whole {
  width: 100%; }

/*
        Halves
        ------
    */
.one-half {
  width: 50%; }

/*
        Thirds
        ------
    */
.one-third {
  width: 33.3333333%; }

.two-thirds {
  width: 66.6666666%; }

/*
        Quarters
        --------
    */
.one-quarter {
  width: 25%; }

.two-quarters {
  width: 50%; }

.three-quarters {
  width: 75%; }

/*
        Fifths
        ------
    */
.one-fifth {
  width: 20%; }

.two-fifths {
  width: 40%; }

.three-fifths {
  width: 60%; }

.four-fifths {
  width: 80%; }

/*
        Sixths
        ------
    */
.one-sixth {
  width: 16.6666666%; }

.two-sixths {
  width: 33.3333333%; }

.three-sixths {
  width: 50%; }

.four-sixths {
  width: 66.6666666%; }

.five-sixths {
  width: 83.3333333%; }

/*
        Eighths
        -------
    */
.one-eighth {
  width: 12.5%; }

.two-eighths {
  width: 25%; }

.three-eighths {
  width: 37.5%; }

.four-eighths {
  width: 50%; }

.five-eighths {
  width: 62.5%; }

.six-eighths {
  width: 75%; }

.seven-eighths {
  width: 87.5%; }

/*
        Ninths
        ------
    */
.one-ninth {
  width: 11.1111111%; }

.two-ninths {
  width: 22.2222222%; }

.four-ninths {
  width: 44.4444444%; }

.five-ninths {
  width: 55.5555555%; }

.seven-ninths {
  width: 77.7777777%; }

.eight-ninths {
  width: 88.8888888%; }

/*
        Tenths
        ------
    */
.one-tenth {
  width: 10%; }

.two-tenths {
  width: 20%; }

.three-tenths {
  width: 30%; }

.four-tenths {
  width: 40%; }

.five-tenths {
  width: 50%; }

.six-tenths {
  width: 60%; }

.seven-tenths {
  width: 70%; }

.eight-tenths {
  width: 80%; }

.nine-tenths {
  width: 90%; }

/*
        Twelfths
        --------
    */
.one-twelfth {
  width: 8.3333333%; }

.two-twelfths {
  width: 16.6666666%; }

.three-twelfths {
  width: 25%; }

.four-twelfths {
  width: 33.3333333%; }

.five-twelfths {
  width: 41.6666666%; }

.six-twelfths {
  width: 50%; }

.seven-twelfths {
  width: 58.3333333%; }

.eight-twelfths {
  width: 66.6666666%; }

.nine-twelfths {
  width: 75%; }

.ten-twelfths {
  width: 83.3333333%; }

.eleven-twelfths {
  width: 91.6666666%; }

/*
        Twentieth
        --------
    */
.one-twentieth {
  width: 5%; }

.two-twentieths {
  width: 10%; }

.three-twentieths {
  width: 15%; }

.four-twentieths {
  width: 20%; }

.five-twentieths {
  width: 25%; }

.six-twentieths {
  width: 30%; }

.seven-twentieths {
  width: 35%; }

.eight-twentieths {
  width: 40%; }

.nine-twentieths {
  width: 45%; }

.ten-twentieths {
  width: 50%; }

.eleven-twentieths {
  width: 55%; }

.twelve-twentieths {
  width: 60%; }

.thirteen-twentieths {
  width: 65%; }

/*
Create more grid sizes below:-

@include respond($bp--grid-small) {
    @include grid-setup("small-");
}
*/
@media (min-width: 28.125em) {
  /*
        Whole
        -----
    */
  .small-one-whole {
    width: 100%; }
  /*
        Halves
        ------
    */
  .small-one-half {
    width: 50%; }
  /*
        Thirds
        ------
    */
  .small-one-third {
    width: 33.3333333%; }
  .small-two-thirds {
    width: 66.6666666%; }
  /*
        Quarters
        --------
    */
  .small-one-quarter {
    width: 25%; }
  .small-two-quarters {
    width: 50%; }
  .small-three-quarters {
    width: 75%; }
  /*
        Fifths
        ------
    */
  .small-one-fifth {
    width: 20%; }
  .small-two-fifths {
    width: 40%; }
  .small-three-fifths {
    width: 60%; }
  .small-four-fifths {
    width: 80%; }
  /*
        Sixths
        ------
    */
  .small-one-sixth {
    width: 16.6666666%; }
  .small-two-sixths {
    width: 33.3333333%; }
  .small-three-sixths {
    width: 50%; }
  .small-four-sixths {
    width: 66.6666666%; }
  .small-five-sixths {
    width: 83.3333333%; }
  /*
        Eighths
        -------
    */
  .small-one-eighth {
    width: 12.5%; }
  .small-two-eighths {
    width: 25%; }
  .small-three-eighths {
    width: 37.5%; }
  .small-four-eighths {
    width: 50%; }
  .small-five-eighths {
    width: 62.5%; }
  .small-six-eighths {
    width: 75%; }
  .small-seven-eighths {
    width: 87.5%; }
  /*
        Ninths
        ------
    */
  .small-one-ninth {
    width: 11.1111111%; }
  .small-two-ninths {
    width: 22.2222222%; }
  .small-four-ninths {
    width: 44.4444444%; }
  .small-five-ninths {
    width: 55.5555555%; }
  .small-seven-ninths {
    width: 77.7777777%; }
  .small-eight-ninths {
    width: 88.8888888%; }
  /*
        Tenths
        ------
    */
  .small-one-tenth {
    width: 10%; }
  .small-two-tenths {
    width: 20%; }
  .small-three-tenths {
    width: 30%; }
  .small-four-tenths {
    width: 40%; }
  .small-five-tenths {
    width: 50%; }
  .small-six-tenths {
    width: 60%; }
  .small-seven-tenths {
    width: 70%; }
  .small-eight-tenths {
    width: 80%; }
  .small-nine-tenths {
    width: 90%; }
  /*
        Twelfths
        --------
    */
  .small-one-twelfth {
    width: 8.3333333%; }
  .small-two-twelfths {
    width: 16.6666666%; }
  .small-three-twelfths {
    width: 25%; }
  .small-four-twelfths {
    width: 33.3333333%; }
  .small-five-twelfths {
    width: 41.6666666%; }
  .small-six-twelfths {
    width: 50%; }
  .small-seven-twelfths {
    width: 58.3333333%; }
  .small-eight-twelfths {
    width: 66.6666666%; }
  .small-nine-twelfths {
    width: 75%; }
  .small-ten-twelfths {
    width: 83.3333333%; }
  .small-eleven-twelfths {
    width: 91.6666666%; }
  /*
        Twentieth
        --------
    */
  .small-one-twentieth {
    width: 5%; }
  .small-two-twentieths {
    width: 10%; }
  .small-three-twentieths {
    width: 15%; }
  .small-four-twentieths {
    width: 20%; }
  .small-five-twentieths {
    width: 25%; }
  .small-six-twentieths {
    width: 30%; }
  .small-seven-twentieths {
    width: 35%; }
  .small-eight-twentieths {
    width: 40%; }
  .small-nine-twentieths {
    width: 45%; }
  .small-ten-twentieths {
    width: 50%; }
  .small-eleven-twentieths {
    width: 55%; }
  .small-twelve-twentieths {
    width: 60%; }
  .small-thirteen-twentieths {
    width: 65%; } }

@media (min-width: 34.375em) {
  /*
        Whole
        -----
    */
  .medium-one-whole {
    width: 100%; }
  /*
        Halves
        ------
    */
  .medium-one-half {
    width: 50%; }
  /*
        Thirds
        ------
    */
  .medium-one-third {
    width: 33.3333333%; }
  .medium-two-thirds {
    width: 66.6666666%; }
  /*
        Quarters
        --------
    */
  .medium-one-quarter {
    width: 25%; }
  .medium-two-quarters {
    width: 50%; }
  .medium-three-quarters {
    width: 75%; }
  /*
        Fifths
        ------
    */
  .medium-one-fifth {
    width: 20%; }
  .medium-two-fifths {
    width: 40%; }
  .medium-three-fifths {
    width: 60%; }
  .medium-four-fifths {
    width: 80%; }
  /*
        Sixths
        ------
    */
  .medium-one-sixth {
    width: 16.6666666%; }
  .medium-two-sixths {
    width: 33.3333333%; }
  .medium-three-sixths {
    width: 50%; }
  .medium-four-sixths {
    width: 66.6666666%; }
  .medium-five-sixths {
    width: 83.3333333%; }
  /*
        Eighths
        -------
    */
  .medium-one-eighth {
    width: 12.5%; }
  .medium-two-eighths {
    width: 25%; }
  .medium-three-eighths {
    width: 37.5%; }
  .medium-four-eighths {
    width: 50%; }
  .medium-five-eighths {
    width: 62.5%; }
  .medium-six-eighths {
    width: 75%; }
  .medium-seven-eighths {
    width: 87.5%; }
  /*
        Ninths
        ------
    */
  .medium-one-ninth {
    width: 11.1111111%; }
  .medium-two-ninths {
    width: 22.2222222%; }
  .medium-four-ninths {
    width: 44.4444444%; }
  .medium-five-ninths {
    width: 55.5555555%; }
  .medium-seven-ninths {
    width: 77.7777777%; }
  .medium-eight-ninths {
    width: 88.8888888%; }
  /*
        Tenths
        ------
    */
  .medium-one-tenth {
    width: 10%; }
  .medium-two-tenths {
    width: 20%; }
  .medium-three-tenths {
    width: 30%; }
  .medium-four-tenths {
    width: 40%; }
  .medium-five-tenths {
    width: 50%; }
  .medium-six-tenths {
    width: 60%; }
  .medium-seven-tenths {
    width: 70%; }
  .medium-eight-tenths {
    width: 80%; }
  .medium-nine-tenths {
    width: 90%; }
  /*
        Twelfths
        --------
    */
  .medium-one-twelfth {
    width: 8.3333333%; }
  .medium-two-twelfths {
    width: 16.6666666%; }
  .medium-three-twelfths {
    width: 25%; }
  .medium-four-twelfths {
    width: 33.3333333%; }
  .medium-five-twelfths {
    width: 41.6666666%; }
  .medium-six-twelfths {
    width: 50%; }
  .medium-seven-twelfths {
    width: 58.3333333%; }
  .medium-eight-twelfths {
    width: 66.6666666%; }
  .medium-nine-twelfths {
    width: 75%; }
  .medium-ten-twelfths {
    width: 83.3333333%; }
  .medium-eleven-twelfths {
    width: 91.6666666%; }
  /*
        Twentieth
        --------
    */
  .medium-one-twentieth {
    width: 5%; }
  .medium-two-twentieths {
    width: 10%; }
  .medium-three-twentieths {
    width: 15%; }
  .medium-four-twentieths {
    width: 20%; }
  .medium-five-twentieths {
    width: 25%; }
  .medium-six-twentieths {
    width: 30%; }
  .medium-seven-twentieths {
    width: 35%; }
  .medium-eight-twentieths {
    width: 40%; }
  .medium-nine-twentieths {
    width: 45%; }
  .medium-ten-twentieths {
    width: 50%; }
  .medium-eleven-twentieths {
    width: 55%; }
  .medium-twelve-twentieths {
    width: 60%; }
  .medium-thirteen-twentieths {
    width: 65%; } }

@media (min-width: 48.125em) {
  /*
        Whole
        -----
    */
  .large-one-whole {
    width: 100%; }
  /*
        Halves
        ------
    */
  .large-one-half {
    width: 50%; }
  /*
        Thirds
        ------
    */
  .large-one-third {
    width: 33.3333333%; }
  .large-two-thirds {
    width: 66.6666666%; }
  /*
        Quarters
        --------
    */
  .large-one-quarter {
    width: 25%; }
  .large-two-quarters {
    width: 50%; }
  .large-three-quarters {
    width: 75%; }
  /*
        Fifths
        ------
    */
  .large-one-fifth {
    width: 20%; }
  .large-two-fifths {
    width: 40%; }
  .large-three-fifths {
    width: 60%; }
  .large-four-fifths {
    width: 80%; }
  /*
        Sixths
        ------
    */
  .large-one-sixth {
    width: 16.6666666%; }
  .large-two-sixths {
    width: 33.3333333%; }
  .large-three-sixths {
    width: 50%; }
  .large-four-sixths {
    width: 66.6666666%; }
  .large-five-sixths {
    width: 83.3333333%; }
  /*
        Eighths
        -------
    */
  .large-one-eighth {
    width: 12.5%; }
  .large-two-eighths {
    width: 25%; }
  .large-three-eighths {
    width: 37.5%; }
  .large-four-eighths {
    width: 50%; }
  .large-five-eighths {
    width: 62.5%; }
  .large-six-eighths {
    width: 75%; }
  .large-seven-eighths {
    width: 87.5%; }
  /*
        Ninths
        ------
    */
  .large-one-ninth {
    width: 11.1111111%; }
  .large-two-ninths {
    width: 22.2222222%; }
  .large-four-ninths {
    width: 44.4444444%; }
  .large-five-ninths {
    width: 55.5555555%; }
  .large-seven-ninths {
    width: 77.7777777%; }
  .large-eight-ninths {
    width: 88.8888888%; }
  /*
        Tenths
        ------
    */
  .large-one-tenth {
    width: 10%; }
  .large-two-tenths {
    width: 20%; }
  .large-three-tenths {
    width: 30%; }
  .large-four-tenths {
    width: 40%; }
  .large-five-tenths {
    width: 50%; }
  .large-six-tenths {
    width: 60%; }
  .large-seven-tenths {
    width: 70%; }
  .large-eight-tenths {
    width: 80%; }
  .large-nine-tenths {
    width: 90%; }
  /*
        Twelfths
        --------
    */
  .large-one-twelfth {
    width: 8.3333333%; }
  .large-two-twelfths {
    width: 16.6666666%; }
  .large-three-twelfths {
    width: 25%; }
  .large-four-twelfths {
    width: 33.3333333%; }
  .large-five-twelfths {
    width: 41.6666666%; }
  .large-six-twelfths {
    width: 50%; }
  .large-seven-twelfths {
    width: 58.3333333%; }
  .large-eight-twelfths {
    width: 66.6666666%; }
  .large-nine-twelfths {
    width: 75%; }
  .large-ten-twelfths {
    width: 83.3333333%; }
  .large-eleven-twelfths {
    width: 91.6666666%; }
  /*
        Twentieth
        --------
    */
  .large-one-twentieth {
    width: 5%; }
  .large-two-twentieths {
    width: 10%; }
  .large-three-twentieths {
    width: 15%; }
  .large-four-twentieths {
    width: 20%; }
  .large-five-twentieths {
    width: 25%; }
  .large-six-twentieths {
    width: 30%; }
  .large-seven-twentieths {
    width: 35%; }
  .large-eight-twentieths {
    width: 40%; }
  .large-nine-twentieths {
    width: 45%; }
  .large-ten-twentieths {
    width: 50%; }
  .large-eleven-twentieths {
    width: 55%; }
  .large-twelve-twentieths {
    width: 60%; }
  .large-thirteen-twentieths {
    width: 65%; } }

@media (min-width: 56.25em) {
  /*
        Whole
        -----
    */
  .larger-one-whole {
    width: 100%; }
  /*
        Halves
        ------
    */
  .larger-one-half {
    width: 50%; }
  /*
        Thirds
        ------
    */
  .larger-one-third {
    width: 33.3333333%; }
  .larger-two-thirds {
    width: 66.6666666%; }
  /*
        Quarters
        --------
    */
  .larger-one-quarter {
    width: 25%; }
  .larger-two-quarters {
    width: 50%; }
  .larger-three-quarters {
    width: 75%; }
  /*
        Fifths
        ------
    */
  .larger-one-fifth {
    width: 20%; }
  .larger-two-fifths {
    width: 40%; }
  .larger-three-fifths {
    width: 60%; }
  .larger-four-fifths {
    width: 80%; }
  /*
        Sixths
        ------
    */
  .larger-one-sixth {
    width: 16.6666666%; }
  .larger-two-sixths {
    width: 33.3333333%; }
  .larger-three-sixths {
    width: 50%; }
  .larger-four-sixths {
    width: 66.6666666%; }
  .larger-five-sixths {
    width: 83.3333333%; }
  /*
        Eighths
        -------
    */
  .larger-one-eighth {
    width: 12.5%; }
  .larger-two-eighths {
    width: 25%; }
  .larger-three-eighths {
    width: 37.5%; }
  .larger-four-eighths {
    width: 50%; }
  .larger-five-eighths {
    width: 62.5%; }
  .larger-six-eighths {
    width: 75%; }
  .larger-seven-eighths {
    width: 87.5%; }
  /*
        Ninths
        ------
    */
  .larger-one-ninth {
    width: 11.1111111%; }
  .larger-two-ninths {
    width: 22.2222222%; }
  .larger-four-ninths {
    width: 44.4444444%; }
  .larger-five-ninths {
    width: 55.5555555%; }
  .larger-seven-ninths {
    width: 77.7777777%; }
  .larger-eight-ninths {
    width: 88.8888888%; }
  /*
        Tenths
        ------
    */
  .larger-one-tenth {
    width: 10%; }
  .larger-two-tenths {
    width: 20%; }
  .larger-three-tenths {
    width: 30%; }
  .larger-four-tenths {
    width: 40%; }
  .larger-five-tenths {
    width: 50%; }
  .larger-six-tenths {
    width: 60%; }
  .larger-seven-tenths {
    width: 70%; }
  .larger-eight-tenths {
    width: 80%; }
  .larger-nine-tenths {
    width: 90%; }
  /*
        Twelfths
        --------
    */
  .larger-one-twelfth {
    width: 8.3333333%; }
  .larger-two-twelfths {
    width: 16.6666666%; }
  .larger-three-twelfths {
    width: 25%; }
  .larger-four-twelfths {
    width: 33.3333333%; }
  .larger-five-twelfths {
    width: 41.6666666%; }
  .larger-six-twelfths {
    width: 50%; }
  .larger-seven-twelfths {
    width: 58.3333333%; }
  .larger-eight-twelfths {
    width: 66.6666666%; }
  .larger-nine-twelfths {
    width: 75%; }
  .larger-ten-twelfths {
    width: 83.3333333%; }
  .larger-eleven-twelfths {
    width: 91.6666666%; }
  /*
        Twentieth
        --------
    */
  .larger-one-twentieth {
    width: 5%; }
  .larger-two-twentieths {
    width: 10%; }
  .larger-three-twentieths {
    width: 15%; }
  .larger-four-twentieths {
    width: 20%; }
  .larger-five-twentieths {
    width: 25%; }
  .larger-six-twentieths {
    width: 30%; }
  .larger-seven-twentieths {
    width: 35%; }
  .larger-eight-twentieths {
    width: 40%; }
  .larger-nine-twentieths {
    width: 45%; }
  .larger-ten-twentieths {
    width: 50%; }
  .larger-eleven-twentieths {
    width: 55%; }
  .larger-twelve-twentieths {
    width: 60%; }
  .larger-thirteen-twentieths {
    width: 65%; } }

@media (min-width: 80em) {
  /*
        Whole
        -----
    */
  .largest-one-whole {
    width: 100%; }
  /*
        Halves
        ------
    */
  .largest-one-half {
    width: 50%; }
  /*
        Thirds
        ------
    */
  .largest-one-third {
    width: 33.3333333%; }
  .largest-two-thirds {
    width: 66.6666666%; }
  /*
        Quarters
        --------
    */
  .largest-one-quarter {
    width: 25%; }
  .largest-two-quarters {
    width: 50%; }
  .largest-three-quarters {
    width: 75%; }
  /*
        Fifths
        ------
    */
  .largest-one-fifth {
    width: 20%; }
  .largest-two-fifths {
    width: 40%; }
  .largest-three-fifths {
    width: 60%; }
  .largest-four-fifths {
    width: 80%; }
  /*
        Sixths
        ------
    */
  .largest-one-sixth {
    width: 16.6666666%; }
  .largest-two-sixths {
    width: 33.3333333%; }
  .largest-three-sixths {
    width: 50%; }
  .largest-four-sixths {
    width: 66.6666666%; }
  .largest-five-sixths {
    width: 83.3333333%; }
  /*
        Eighths
        -------
    */
  .largest-one-eighth {
    width: 12.5%; }
  .largest-two-eighths {
    width: 25%; }
  .largest-three-eighths {
    width: 37.5%; }
  .largest-four-eighths {
    width: 50%; }
  .largest-five-eighths {
    width: 62.5%; }
  .largest-six-eighths {
    width: 75%; }
  .largest-seven-eighths {
    width: 87.5%; }
  /*
        Ninths
        ------
    */
  .largest-one-ninth {
    width: 11.1111111%; }
  .largest-two-ninths {
    width: 22.2222222%; }
  .largest-four-ninths {
    width: 44.4444444%; }
  .largest-five-ninths {
    width: 55.5555555%; }
  .largest-seven-ninths {
    width: 77.7777777%; }
  .largest-eight-ninths {
    width: 88.8888888%; }
  /*
        Tenths
        ------
    */
  .largest-one-tenth {
    width: 10%; }
  .largest-two-tenths {
    width: 20%; }
  .largest-three-tenths {
    width: 30%; }
  .largest-four-tenths {
    width: 40%; }
  .largest-five-tenths {
    width: 50%; }
  .largest-six-tenths {
    width: 60%; }
  .largest-seven-tenths {
    width: 70%; }
  .largest-eight-tenths {
    width: 80%; }
  .largest-nine-tenths {
    width: 90%; }
  /*
        Twelfths
        --------
    */
  .largest-one-twelfth {
    width: 8.3333333%; }
  .largest-two-twelfths {
    width: 16.6666666%; }
  .largest-three-twelfths {
    width: 25%; }
  .largest-four-twelfths {
    width: 33.3333333%; }
  .largest-five-twelfths {
    width: 41.6666666%; }
  .largest-six-twelfths {
    width: 50%; }
  .largest-seven-twelfths {
    width: 58.3333333%; }
  .largest-eight-twelfths {
    width: 66.6666666%; }
  .largest-nine-twelfths {
    width: 75%; }
  .largest-ten-twelfths {
    width: 83.3333333%; }
  .largest-eleven-twelfths {
    width: 91.6666666%; }
  /*
        Twentieth
        --------
    */
  .largest-one-twentieth {
    width: 5%; }
  .largest-two-twentieths {
    width: 10%; }
  .largest-three-twentieths {
    width: 15%; }
  .largest-four-twentieths {
    width: 20%; }
  .largest-five-twentieths {
    width: 25%; }
  .largest-six-twentieths {
    width: 30%; }
  .largest-seven-twentieths {
    width: 35%; }
  .largest-eight-twentieths {
    width: 40%; }
  .largest-nine-twentieths {
    width: 45%; }
  .largest-ten-twentieths {
    width: 50%; }
  .largest-eleven-twentieths {
    width: 55%; }
  .largest-twelve-twentieths {
    width: 60%; }
  .largest-thirteen-twentieths {
    width: 65%; } }

@media (min-width: 96.875em) {
  /*
        Whole
        -----
    */
  .largester-one-whole {
    width: 100%; }
  /*
        Halves
        ------
    */
  .largester-one-half {
    width: 50%; }
  /*
        Thirds
        ------
    */
  .largester-one-third {
    width: 33.3333333%; }
  .largester-two-thirds {
    width: 66.6666666%; }
  /*
        Quarters
        --------
    */
  .largester-one-quarter {
    width: 25%; }
  .largester-two-quarters {
    width: 50%; }
  .largester-three-quarters {
    width: 75%; }
  /*
        Fifths
        ------
    */
  .largester-one-fifth {
    width: 20%; }
  .largester-two-fifths {
    width: 40%; }
  .largester-three-fifths {
    width: 60%; }
  .largester-four-fifths {
    width: 80%; }
  /*
        Sixths
        ------
    */
  .largester-one-sixth {
    width: 16.6666666%; }
  .largester-two-sixths {
    width: 33.3333333%; }
  .largester-three-sixths {
    width: 50%; }
  .largester-four-sixths {
    width: 66.6666666%; }
  .largester-five-sixths {
    width: 83.3333333%; }
  /*
        Eighths
        -------
    */
  .largester-one-eighth {
    width: 12.5%; }
  .largester-two-eighths {
    width: 25%; }
  .largester-three-eighths {
    width: 37.5%; }
  .largester-four-eighths {
    width: 50%; }
  .largester-five-eighths {
    width: 62.5%; }
  .largester-six-eighths {
    width: 75%; }
  .largester-seven-eighths {
    width: 87.5%; }
  /*
        Ninths
        ------
    */
  .largester-one-ninth {
    width: 11.1111111%; }
  .largester-two-ninths {
    width: 22.2222222%; }
  .largester-four-ninths {
    width: 44.4444444%; }
  .largester-five-ninths {
    width: 55.5555555%; }
  .largester-seven-ninths {
    width: 77.7777777%; }
  .largester-eight-ninths {
    width: 88.8888888%; }
  /*
        Tenths
        ------
    */
  .largester-one-tenth {
    width: 10%; }
  .largester-two-tenths {
    width: 20%; }
  .largester-three-tenths {
    width: 30%; }
  .largester-four-tenths {
    width: 40%; }
  .largester-five-tenths {
    width: 50%; }
  .largester-six-tenths {
    width: 60%; }
  .largester-seven-tenths {
    width: 70%; }
  .largester-eight-tenths {
    width: 80%; }
  .largester-nine-tenths {
    width: 90%; }
  /*
        Twelfths
        --------
    */
  .largester-one-twelfth {
    width: 8.3333333%; }
  .largester-two-twelfths {
    width: 16.6666666%; }
  .largester-three-twelfths {
    width: 25%; }
  .largester-four-twelfths {
    width: 33.3333333%; }
  .largester-five-twelfths {
    width: 41.6666666%; }
  .largester-six-twelfths {
    width: 50%; }
  .largester-seven-twelfths {
    width: 58.3333333%; }
  .largester-eight-twelfths {
    width: 66.6666666%; }
  .largester-nine-twelfths {
    width: 75%; }
  .largester-ten-twelfths {
    width: 83.3333333%; }
  .largester-eleven-twelfths {
    width: 91.6666666%; }
  /*
        Twentieth
        --------
    */
  .largester-one-twentieth {
    width: 5%; }
  .largester-two-twentieths {
    width: 10%; }
  .largester-three-twentieths {
    width: 15%; }
  .largester-four-twentieths {
    width: 20%; }
  .largester-five-twentieths {
    width: 25%; }
  .largester-six-twentieths {
    width: 30%; }
  .largester-seven-twentieths {
    width: 35%; }
  .largester-eight-twentieths {
    width: 40%; }
  .largester-nine-twentieths {
    width: 45%; }
  .largester-ten-twentieths {
    width: 50%; }
  .largester-eleven-twentieths {
    width: 55%; }
  .largester-twelve-twentieths {
    width: 60%; }
  .largester-thirteen-twentieths {
    width: 65%; } }

/*  ==========================================================================
    Helpers:Layout
    ========================================================================== */
/*
    Contain floats
    --------------

    Make an element expand to contain floated children.
    Uses pseudo-elements (micro clearfix).

    1. The space content is one way to avoid an Opera bug when the
        `contenteditable` attribute is included anywhere else in the document.
        Otherwise it causes space to appear at the top and bottom of the
        element.
    2. The use of `table` rather than `block` is only necessary if using
        `:before` to contain the top-margins of child elements.
 */
.cf:before, .cf:after,
.group:before,
.group:after {
  content: "";
  /* 1 */
  display: table;
  /* 2 */ }

.cf:after,
.group:after {
  clear: both; }

/*
    Floats
*/
.float--right {
  float: right !important; }

.float--left {
  float: left !important; }

/*  ==========================================================================
    Helpers:Rendering
    ========================================================================== */
.will-change {
  /*
        Promote item to it's own layer

        http://aerotwist.com/blog/on-translate3d-and-layer-creation-hacks/

        at some point change this to the new property will-change
        http://aerotwist.com/blog/bye-bye-layer-hacks/
    */
  transform: translateZ(0); }

/*  ==========================================================================
    Generic:Helpers:Spacing
    ========================================================================== */
/*
    add/remove margins
    ------------------
*/
.push {
  margin: 1.66667em !important; }

.push--top {
  margin-top: 1.66667em !important; }

.push--right {
  margin-right: 1.66667em !important; }

.push--bottom {
  margin-bottom: 1.66667em !important; }

.push--left {
  margin-left: 1.66667em !important; }

.push--ends {
  margin-top: 1.66667em !important;
  margin-bottom: 1.66667em !important; }

.push--sides {
  margin-right: 1.66667em !important;
  margin-left: 1.66667em !important; }

.push-half {
  margin: 0.83333em !important; }

.push-half--top {
  margin-top: 0.83333em !important; }

.push-half--right {
  margin-right: 0.83333em !important; }

.push-half--bottom {
  margin-bottom: 0.83333em !important; }

.push-half--left {
  margin-left: 0.83333em !important; }

.push-half--ends {
  margin-top: 0.83333em !important;
  margin-bottom: 0.83333em !important; }

.push-half--sides {
  margin-right: 0.83333em !important;
  margin-left: 0.83333em !important; }

.flush {
  margin: 0 !important; }

.flush--top {
  margin-top: 0 !important; }

.flush--right {
  margin-right: 0 !important; }

.flush--bottom {
  margin-bottom: 0 !important; }

.flush--left {
  margin-left: 0 !important; }

.flush--ends {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.flush--sides {
  margin-right: 0 !important;
  margin-left: 0 !important; }

/*
    remove paddings
    ---------------
    I don't find myself wanting to add padding via a helper class so i only have classes for removing
*/
.hard {
  padding: 0 !important; }

.hard--top {
  padding-top: 0 !important; }

.hard--right {
  padding-right: 0 !important; }

.hard--bottom {
  padding-bottom: 0 !important; }

.hard--left {
  padding-left: 0 !important; }

.hard--ends {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.hard--sides {
  padding-right: 0 !important;
  padding-left: 0 !important; }

/* ==========================================================================
   Helpers:State
   ========================================================================== */
/*
    Indicate that interaction will result in an (in)action.
*/
.is-actionable {
  cursor: pointer !important; }

.is-disabled {
  cursor: default !important; }

.is-draggable {
  cursor: move !important; }

/*
    Completely remove from the flow and screen readers.
*/
.is-hidden {
  display: none !important;
  visibility: hidden !important; }

/*
    Completely remove from the flow but leave available to screen readers.
*/
.is-hidden-visually {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px); }

/*
    Control visibility without affecting flow.
*/
.is-invisible {
  visibility: hidden !important; }

.is-visible {
  visibility: visible !important; }

/*  ==========================================================================
    Generic:Helpers:Text
    ========================================================================== */
/*
    Text alignment
*/
.text--left {
  text-align: left  !important; }

.text--center {
  text-align: center !important; }

.text--right {
  text-align: right !important; }

/*
    Apply capital case to an element (usually a `strong`).
*/
.caps {
  text-transform: uppercase !important; }

/**
 * Font weights
 */
.weight--light {
  font-weight: 300 !important; }

.weight--normal {
  font-weight: 400 !important; }

.weight--semibold {
  font-weight: 600 !important; }

.weight--bold {
  font-weight: bold !important; }

/*
    Text truncation

    Prevent text from wrapping onto multiple lines, and truncate with an
    ellipsis.

    1.  Ensure that the node has a maximum width after which truncation can
        occur.
    2.  Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
        nodes.
*/
.text--truncate {
  max-width: 100%;
  /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  /* 2 */ }

/*
    font sizes
*/
.alpha {
  font-size: 1.66667em; }

.beta {
  font-size: 1.38889em; }

.gamma {
  font-size: 1.22222em; }

.delta {
  font-size: 1.11111em; }

.epsilon {
  font-size: 1em; }

.zeta {
  font-size: 0.88889em; }

/* 9 */
.cta-boxes--box--title {
  margin-bottom: 4px; }

.cta-boxes--text {
  color: #999999;
  font-size: 0.88889em;
  font-family: Georgia, Times, "Times New Roman", serif; }

.cta-boxes--box--inner--content {
  flex: 1 0 auto; }

.generic-content {
  text-align: left;
  padding: 2.5% 0;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative; }
  .generic-content.image {
    color: #333;
    padding: 3% 0; }
    .generic-content.image h1, .generic-content.image h2, .generic-content.image h3, .generic-content.image h4, .generic-content.image h5, .generic-content.image h6, .generic-content.image .generic-content--title {
      color: #333;
      font-weight: 300; }
    .generic-content.image .generic-content--title {
      font-size: 25px;
      text-transform: uppercase; }
    .generic-content.image .generic-content--background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0; }
      .generic-content.image .generic-content--background-image img {
        width: 100%;
        height: 100%; }
  .generic-content .generic-content--content {
    position: relative;
    z-index: 2; }
  .generic-content .generic-content--title {
    font-size: 25px; }
  .generic-content .generic-content--inner-content strong {
    color: blue; }
  .generic-content .generic-content--inner-content p {
    margin-bottom: 2%;
    line-height: 30px;
    font-weight: 100;
    font-size: 22px; }
    @media only screen and (max-width: 34.375em) {
      .generic-content .generic-content--inner-content p {
        font-size: 1rem;
        line-height: 1.3rem; } }

.grid--youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }
  .grid--youtube-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.grid--image-gallery img {
  width: 33.3%;
  height: 124.3px;
  margin: 0;
  padding: 0; }

.grid--standard-content {
  padding: 3% 9% 6%;
  text-align: center;
  color: #fff; }
  .grid--standard-content h1, .grid--standard-content h2, .grid--standard-content h3, .grid--standard-content h4, .grid--standard-content h5, .grid--standard-content h6, .grid--standard-content .grid--standard-content--title {
    text-transform: uppercase;
    max-width: 320px;
    display: inline-block; }

.set_width_layouts {
  padding: 10% 9px;
  background: #fff; }
  @media (min-width: 48.125em) {
    .set_width_layouts {
      padding: 6% 37px; } }
  @media (min-width: 56.25em) {
    .set_width_layouts {
      padding: 2% 0; } }
  @media (min-width: 80em) {
    .set_width_layouts {
      padding: 30px 0; } }
  .set_width_layouts.set_width_layouts--grey {
    background: #333; }
    .set_width_layouts.set_width_layouts--grey .set_width_layouts__title {
      color: #d3d3d3;
      font-size: 24px;
      font-family: Georgia, Times, "Times New Roman", serif;
      font-weight: 400;
      text-transform: uppercase;
      margin: 0 0 15%;
      letter-spacing: 0.3em; }
      @media (min-width: 48.125em) {
        .set_width_layouts.set_width_layouts--grey .set_width_layouts__title {
          margin: 0 0 10%; } }
      @media (min-width: 56.25em) {
        .set_width_layouts.set_width_layouts--grey .set_width_layouts__title {
          margin: 0 0 5%; } }
      .set_width_layouts.set_width_layouts--grey .set_width_layouts__title::after {
        content: '';
        display: block;
        margin: 10px auto;
        width: 200px;
        height: 1px;
        background: #d3d3d3; }
  @media (min-width: 56.25em) {
    .set_width_layouts .set_width_layout.larger-one-half:nth-child(even) {
      border-left: 1px solid #d3d3d3; } }
  .set_width_layouts .set_width_layout .set_width_layout--standard-content--text p {
    padding-bottom: 10px; }

.set_width_layout--video {
  position: relative;
  padding-top: 0; }
  .set_width_layout--video iframe {
    position: relative;
    width: 100%;
    display: block; }
  .set_width_layout--video .inner {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column; }
    .set_width_layout--video .inner .inner__content {
      flex: 1 0 auto; }

.set_width_layout--image-gallery {
  font-size: 0; }
  .set_width_layout--image-gallery img {
    width: 33.3%;
    height: 128.3px;
    margin: 0;
    padding: 2px; }

.set_width_layout--standard-content {
  margin-bottom: 35px; }
  .set_width_layout--standard-content:last-of-type {
    margin-bottom: 0; }
  @media (min-width: 48.125em) {
    .set_width_layout--standard-content.large-one-half:nth-child(odd) {
      border-right: 1px solid #333; } }

.set_width_layout--standard-content, .set_width_layout--links {
  position: relative; }
  .set_width_layout--standard-content .set_width_layout--standard-content--inner, .set_width_layout--links .set_width_layout--standard-content--inner {
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 1% 0; }
    .set_width_layout--standard-content .set_width_layout--standard-content--inner .set_width_layout--standard-content--inner__content, .set_width_layout--links .set_width_layout--standard-content--inner .set_width_layout--standard-content--inner__content {
      flex: 1 0 auto;
      padding: 0px 22px; }
  .set_width_layout--standard-content .set_width_layout--standard-content--bg-image, .set_width_layout--links .set_width_layout--standard-content--bg-image {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 0; }
  .set_width_layout--standard-content h1, .set_width_layout--standard-content h2, .set_width_layout--standard-content h3, .set_width_layout--standard-content h4, .set_width_layout--standard-content h5, .set_width_layout--standard-content h6, .set_width_layout--standard-content .set_width_layout--standard-content--title, .set_width_layout--links h1, .set_width_layout--links h2, .set_width_layout--links h3, .set_width_layout--links h4, .set_width_layout--links h5, .set_width_layout--links h6, .set_width_layout--links .set_width_layout--standard-content--title {
    display: block;
    line-height: 1.2em;
    margin: 0.5em 0; }
  .set_width_layout--standard-content .set_width_layout--standard-content--text, .set_width_layout--links .set_width_layout--standard-content--text {
    font-size: 1em;
    font-family: Georgia, Times, "Times New Roman", serif; }
    .set_width_layout--standard-content .set_width_layout--standard-content--text h1, .set_width_layout--standard-content .set_width_layout--standard-content--text h2, .set_width_layout--links .set_width_layout--standard-content--text h1, .set_width_layout--links .set_width_layout--standard-content--text h2 {
      font-family: Georgia, Times, "Times New Roman", serif;
      font-size: 36px;
      color: #333;
      margin: 0;
      padding-bottom: 30px; }
      @media (min-width: 48.125em) {
        .set_width_layout--standard-content .set_width_layout--standard-content--text h1, .set_width_layout--standard-content .set_width_layout--standard-content--text h2, .set_width_layout--links .set_width_layout--standard-content--text h1, .set_width_layout--links .set_width_layout--standard-content--text h2 {
          font-size: 48px; } }
      .set_width_layout--standard-content .set_width_layout--standard-content--text h1:after, .set_width_layout--standard-content .set_width_layout--standard-content--text h2:after, .set_width_layout--links .set_width_layout--standard-content--text h1:after, .set_width_layout--links .set_width_layout--standard-content--text h2:after {
        content: '';
        display: block;
        width: 100%;
        border-top: 1px solid #d3d3d3;
        margin-top: 30px; }
        .set_width_layouts--grey .set_width_layout--standard-content .set_width_layout--standard-content--text h1:after, .set_width_layouts--grey .set_width_layout--standard-content .set_width_layout--standard-content--text h2:after, .set_width_layouts--grey .set_width_layout--links .set_width_layout--standard-content--text h1:after, .set_width_layouts--grey .set_width_layout--links .set_width_layout--standard-content--text h2:after {
          border-top: 1px solid #d3d3d3; }
      .home .set_width_layout--standard-content .set_width_layout--standard-content--text h1, .home .set_width_layout--standard-content .set_width_layout--standard-content--text h2, .home .set_width_layout--links .set_width_layout--standard-content--text h1, .home .set_width_layout--links .set_width_layout--standard-content--text h2 {
        padding-bottom: 0; }
        .home .set_width_layout--standard-content .set_width_layout--standard-content--text h1:after, .home .set_width_layout--standard-content .set_width_layout--standard-content--text h2:after, .home .set_width_layout--links .set_width_layout--standard-content--text h1:after, .home .set_width_layout--links .set_width_layout--standard-content--text h2:after {
          display: none; }
  .set_width_layout--standard-content.brand-blue .set_width_layout--standard-content--inner, .set_width_layout--links.brand-blue .set_width_layout--standard-content--inner {
    background: #333;
    color: #fff; }
  .set_width_layout--standard-content.brand-blue a, .set_width_layout--links.brand-blue a {
    color: #fff; }
  .set_width_layout--standard-content.brand-blue .set_width_layout--standard-content--inner, .set_width_layout--links.brand-blue .set_width_layout--standard-content--inner {
    padding: 1% 0 2% 0; }

.set_width_layouts .cta-boxes--box--title {
  margin-top: 0;
  color: #333; }

.set_width_layouts .cta-boxes--box--inner {
  width: 100%;
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: column; }
  .set_width_layouts .cta-boxes--box--inner img {
    width: 100%;
    display: block; }

.set_width_layouts.set_width_layouts--white .cta-boxes--box--inner {
  background: #d3d3d3; }

.set_width_layouts .cta-boxes--box--inner--content {
  padding: 12px 18px 18px; }

.blog .cta-boxes--box--inner--content, .archive .cta-boxes--box--inner--content {
  width: 100%;
  position: relative;
  background: #d3d3d3; }

.set_width_layout--testimonials {
  margin-bottom: 35px; }
  .set_width_layout--testimonials .testimonials {
    padding-top: 14px;
    padding-bottom: 60px; }
    @media (min-width: 48.125em) {
      .set_width_layout--testimonials .testimonials {
        padding-bottom: 40px; } }
  .set_width_layout--testimonials .slick-track {
    display: flex; }
  .set_width_layout--testimonials .slick-next, .set_width_layout--testimonials .slick-prev {
    top: auto;
    bottom: 0;
    position: absolute;
    width: 50%;
    border: 1px solid #fff;
    text-indent: 0;
    transition: all 0.2s;
    text-transform: lowercase;
    font-size: 19px; }
    .set_width_layout--testimonials .slick-next:hover, .set_width_layout--testimonials .slick-prev:hover {
      background: #fff;
      color: #d3d3d3; }
      .set_width_layout--testimonials .slick-next:hover:after, .set_width_layout--testimonials .slick-prev:hover:after {
        background: url("/content/themes/standard/images/sprite.png") 0 0 no-repeat; }
    .set_width_layout--testimonials .slick-next:after, .set_width_layout--testimonials .slick-prev:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 14px;
      right: 14px;
      height: 15px;
      width: 8px;
      background: url("/content/themes/standard/images/sprite.png") -10px 0 no-repeat; }
    .set_width_layout--testimonials .slick-next.slick-next, .set_width_layout--testimonials .slick-prev.slick-next {
      right: 0;
      border-left: 0;
      text-align: left;
      padding-left: 20px; }
    .set_width_layout--testimonials .slick-next.slick-prev, .set_width_layout--testimonials .slick-prev.slick-prev {
      text-align: right;
      padding-right: 20px;
      left: 0; }
      .set_width_layout--testimonials .slick-next.slick-prev:after, .set_width_layout--testimonials .slick-prev.slick-prev:after {
        right: auto;
        left: 14px;
        transform: rotate(180deg); }
  @media (min-width: 48.125em) {
    .set_width_layout--testimonials.large-one-half:nth-child(odd) {
      border-right: 1px solid #d3d3d3; } }

.set_width_layout--testimonials, .set_width_layout--links {
  position: relative; }
  .set_width_layout--testimonials .set_width_layout--testimonials--inner, .set_width_layout--links .set_width_layout--testimonials--inner {
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 1% 0; }
    .set_width_layout--testimonials .set_width_layout--testimonials--inner .set_width_layout--testimonials--inner__content, .set_width_layout--links .set_width_layout--testimonials--inner .set_width_layout--testimonials--inner__content {
      flex: 1 0 auto;
      padding: 0px 22px; }
  .set_width_layout--testimonials .set_width_layout--testimonials--name, .set_width_layout--links .set_width_layout--testimonials--name {
    font-size: 0.8rem;
    float: right;
    margin-top: 5%;
    text-align: right; }
  .set_width_layout--testimonials .set_width_layout--testimonials--bg-image, .set_width_layout--links .set_width_layout--testimonials--bg-image {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 0; }
  .set_width_layout--testimonials h1, .set_width_layout--testimonials h2, .set_width_layout--testimonials h3, .set_width_layout--testimonials h4, .set_width_layout--testimonials h5, .set_width_layout--testimonials h6, .set_width_layout--testimonials .set_width_layout--testimonials--title, .set_width_layout--links h1, .set_width_layout--links h2, .set_width_layout--links h3, .set_width_layout--links h4, .set_width_layout--links h5, .set_width_layout--links h6, .set_width_layout--links .set_width_layout--testimonials--title {
    display: block;
    line-height: 1.2em;
    margin: 0.5em 0; }
  .set_width_layout--testimonials .set_width_layout--testimonials--text, .set_width_layout--links .set_width_layout--testimonials--text {
    font-size: 1em;
    font-family: Georgia, Times, "Times New Roman", serif;
    position: relative;
    text-indent: 35px; }
    .set_width_layout--testimonials .set_width_layout--testimonials--text::before, .set_width_layout--testimonials .set_width_layout--testimonials--text::after, .set_width_layout--links .set_width_layout--testimonials--text::before, .set_width_layout--links .set_width_layout--testimonials--text::after {
      text-indent: 0;
      line-height: 1;
      font-family: FontAwesome;
      font-size: 3.3em;
      display: inline;
      position: absolute; }
    .set_width_layout--testimonials .set_width_layout--testimonials--text::before, .set_width_layout--links .set_width_layout--testimonials--text::before {
      content: '\0201C';
      top: -7px;
      left: 0px; }
    .set_width_layout--testimonials .set_width_layout--testimonials--text p::after, .set_width_layout--links .set_width_layout--testimonials--text p::after {
      content: '\0201D';
      position: relative;
      display: inline;
      font-size: 2.5em;
      line-height: 0em;
      height: 25px;
      top: 18px;
      left: 7px; }
    .set_width_layout--testimonials .set_width_layout--testimonials--text h1, .set_width_layout--testimonials .set_width_layout--testimonials--text h2, .set_width_layout--links .set_width_layout--testimonials--text h1, .set_width_layout--links .set_width_layout--testimonials--text h2 {
      font-family: Georgia, Times, "Times New Roman", serif;
      font-size: 36px;
      color: #333;
      margin: 0;
      padding-bottom: 30px; }
      @media (min-width: 48.125em) {
        .set_width_layout--testimonials .set_width_layout--testimonials--text h1, .set_width_layout--testimonials .set_width_layout--testimonials--text h2, .set_width_layout--links .set_width_layout--testimonials--text h1, .set_width_layout--links .set_width_layout--testimonials--text h2 {
          font-size: 48px; } }
      .set_width_layout--testimonials .set_width_layout--testimonials--text h1:after, .set_width_layout--testimonials .set_width_layout--testimonials--text h2:after, .set_width_layout--links .set_width_layout--testimonials--text h1:after, .set_width_layout--links .set_width_layout--testimonials--text h2:after {
        content: '';
        display: block;
        width: 100%;
        border-top: 1px solid #d3d3d3;
        margin-top: 30px; }
      .home .set_width_layout--testimonials .set_width_layout--testimonials--text h1, .home .set_width_layout--testimonials .set_width_layout--testimonials--text h2, .home .set_width_layout--links .set_width_layout--testimonials--text h1, .home .set_width_layout--links .set_width_layout--testimonials--text h2 {
        padding-bottom: 0; }
        .home .set_width_layout--testimonials .set_width_layout--testimonials--text h1:after, .home .set_width_layout--testimonials .set_width_layout--testimonials--text h2:after, .home .set_width_layout--links .set_width_layout--testimonials--text h1:after, .home .set_width_layout--links .set_width_layout--testimonials--text h2:after {
          display: none; }
  .set_width_layout--testimonials.brand-blue, .set_width_layout--links.brand-blue {
    position: relative; }
    .set_width_layout--testimonials.brand-blue .set_width_layout--testimonials--inner__bg, .set_width_layout--links.brand-blue .set_width_layout--testimonials--inner__bg {
      background: #333;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: -1; }
    .set_width_layout--testimonials.brand-blue .set_width_layout--testimonials--inner, .set_width_layout--links.brand-blue .set_width_layout--testimonials--inner {
      color: #fff; }
    .set_width_layout--testimonials.brand-blue a, .set_width_layout--links.brand-blue a {
      color: #fff; }
    .set_width_layout--testimonials.brand-blue .set_width_layout--testimonials--inner, .set_width_layout--links.brand-blue .set_width_layout--testimonials--inner {
      padding: 1% 0 2% 0; }

.iframe-section iframe {
  width: 100%;
  border: none;
  height: 1080px; }

section.newsletter {
  background-size: cover !important;
  color: #fff;
  padding: 3% 34px 10%; }
  @media (min-width: 56.25em) {
    section.newsletter {
      padding: 3% 0 3%; } }
  section.newsletter .inner {
    max-width: 960px;
    margin: 0 auto; }
  section.newsletter h2 {
    font-size: 36px;
    font-weight: 100;
    padding: 0% 0 2%; }
    section.newsletter h2 strong {
      display: block; }
      @media (min-width: 48.125em) {
        section.newsletter h2 strong {
          display: inline-block; } }
  section.newsletter .control-group {
    margin-right: 2%; }
    @media (min-width: 48.125em) {
      section.newsletter .control-group {
        display: inline-block; } }
    section.newsletter .control-group input {
      border: none;
      font-size: 15px;
      font-family: Georgia, Times, "Times New Roman", serif;
      padding: 3% 3% 2% 3%;
      width: 100%;
      margin-bottom: 19px;
      color: #333; }
      @media (min-width: 48.125em) {
        section.newsletter .control-group input {
          margin-bottom: 0;
          width: 300px; } }
  section.newsletter .btn {
    font-family: Georgia, Times, "Times New Roman", serif;
    margin-top: 5.5%;
    padding: 0.8% 5% 1% 2%;
    text-transform: none;
    color: #fff;
    border: solid 1px #fff;
    background: none;
    position: relative;
    width: 132px; }
    @media (min-width: 48.125em) {
      section.newsletter .btn {
        width: auto; } }

/* 10 */
